import React from 'react';
import ModifyEvent from '../components/events/ModifyEvent';
import NewEventCard from '../components/events/NewEventCard';
import EventCards from '../components/events/EventCards';
import { connect } from 'react-redux';
import {
	eventsData,
	createNewEvt,
	deleteEvt,
	editOldEvt
} from '../services/events/eventsActions.js';
import { Container, Row } from 'react-bootstrap';
import PageFrame from '../containers/app/PageFrame.js';

/**
 * Callback function to accept/reject a score change
 * @typedef {Object} CcKey
 * @property {string} category -  Prefix which represents the age group
 * @property {string} gender - Suffix which represents gender
 **/

/**
 * Callback function to accept/reject a score change
 * @typedef {Object} Coordinator
 * @property {string} id -  Email of the user
 * @property {string} text - Name of the user
 **/

/**
 * @class EventCenterView
 * @classdesc This component is what holds the page for all event creation, editing, and management
 * @param {Object} props
 * @param {Object[]} props.eventCardInfo - each element is props for {@link ModifyEvent} except for 5 things
 * {@link EventCards}, not including modify and delete functions.
 * These are visual representations of events and can be interacted with using the functions just mentioned.
 * @param {Coordinator[]} props.potentialCoordinators - list of all possible coordinators which can be added to an event. Used when modifying an event's coordinators.
 * @param {CcKey[]} props.ccKeys - list of all possible ccKeys (or Category/Gender combinations) which can be chosen for an event. Used when modifying an event's taget-groups/categories.
 * @param {function} props.getEventsData - function to initially populate the state and get the info on all pre-existing events
 * @param {function} props.deleteEvent - function to delete an existing event. Takes one argument (event ID).
 * @param {function} props.editOld - function to save changes from editing an already existing event. Takes one argument containing all relevant
 * information for an event, distinguishing between iformation that has been changed, added, and removed (event body).
 * @param {function} props.saveNew - function to create a new event. Takes one argument containing all relevant information for an event (event body).
 * @returns {React.Component}
 */

class EventCenterView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			eventCreating: false,
			modId: ''
		};
		this.cancel = this.cancel.bind(this);
		this.save = this.save.bind(this);
		this.eventModify = this.eventModify.bind(this);
		this.newEvent = this.newEvent.bind(this);
		this.getNominalComponent = this.getNominalComponent.bind(this);
		this.getNewEventProps = this.getNewEventProps.bind(this);
		this.getModifyEventProps = this.getModifyEventProps.bind(this);
	}

	componentDidMount() {
		this.props.getEventsData();
	}

	cancel() {
		this.setState({ eventCreating: false, modId: '' });
	}

	//fix scroll controller reset
	save(body) {
		if (this.state.modId === 'new') {
			this.props.saveNew(body);
		} else if (this.state.modId !== '') {
			let tempEvt = this.props.eventCardInfo.find(
				obj => obj.event_id === body.id
			);

			let dataToChange = {
				id: body.id,
				changes: [
					...(tempEvt.title !== body.title
						? [{ type: 'title', new: body.title }]
						: []),
					...(tempEvt.location !== body.location
						? [{ type: 'location', new: body.location }]
						: []),
					...(tempEvt.date !== body.date
						? [{ type: 'date', new: body.date }]
						: []),
					...(tempEvt.time.concat(':00') !== body.startTime
						? [{ type: 'time', new: body.startTime }]
						: []),
					...(tempEvt.duration !== body.duration
						? [{ type: 'duration', new: body.duration }]
						: [])
				],
				newCoords: body.coords.filter(
					obj =>
						!tempEvt.coordinators
							.map(ind => ind.id)
							.includes(obj.id)
				),
				removedCoords: tempEvt.coordinators.filter(
					obj => !body.coords.map(ind => ind.id).includes(obj.id)
				),
				newCats: body.categories.filter(
					obj =>
						!tempEvt.ccKeys
							.map(ind => ind.category.concat(' ', ind.gender))
							.includes(obj.category.concat(' ', obj.gender))
				),
				removedCats: tempEvt.ccKeys.filter(
					obj =>
						!body.categories
							.map(ind => ind.category.concat(' ', ind.gender))
							.includes(obj.category.concat(' ', obj.gender))
				)
			};
			this.props.editOld(dataToChange);
		}
		this.setState({ eventCreating: false, modId: '' });
	}

	eventModify(evtID) {
		this.setState({
			modId: evtID,
			eventCreating: true
		});
	}

	newEvent() {
		this.setState({
			modId: 'new',
			eventCreating: true
		});
	}

	getNominalComponent() {
		return (
			<PageFrame title="Event Center">
				<Row xs={2} md={4} className="g-4">
					<NewEventCard newEvent={this.newEvent} />
					{this.props.eventCardInfo.map(evtInfo => (
						<EventCards
							title={evtInfo.title}
							date={evtInfo.displayDate}
							startTime={tConvert(evtInfo.time)}
							duration={evtInfo.duration}
							evtID={evtInfo.event_id}
							delete={this.props.deleteEvent}
							modify={this.eventModify}
						/>
					))}
				</Row>
			</PageFrame>
		);
	}

	getNewEventProps() {
		return {
			title: '',
			loc: '',
			date: '',
			startTime: '',
			duration: '',
			isNew: true,
			save: this.save,
			cancel: this.cancel,
			ccKeys: this.props.ccKeys,
			categories: [],
			unselectedUsers: this.props.potentialCoordinators,
			selectedUsers: []
		};
	}

	getModifyEventProps() {
		let tempEvt = this.props.eventCardInfo.find(
			obj => obj.event_id === this.state.modId
		);
		let selectedCoords = tempEvt.coordinators.map(obj => obj.id);

		return {
			id: tempEvt.event_id,
			title: tempEvt.title,
			loc: tempEvt.location,
			date: tempEvt.date,
			startTime: tempEvt.time,
			duration: tempEvt.duration,
			isNew: false,
			save: this.save,
			cancel: this.cancel,
			ccKeys: this.props.ccKeys,
			categories: tempEvt.ccKeys,
			unselectedUsers: this.props.potentialCoordinators.filter(
				obj => !selectedCoords.includes(obj.id)
			),
			selectedUsers: tempEvt.coordinators
		};
	}

	render() {
		let innerRender = <p>Loading</p>;
		if (this.props.eventCardInfo) {
			if (!this.state.eventCreating) {
				return this.getNominalComponent();
			} else if (this.state.modId === 'new') {
				innerRender = <ModifyEvent {...this.getNewEventProps()} />;
			} else {
				// Modifying event
				innerRender = <ModifyEvent {...this.getModifyEventProps()} />;
			}
		}

		return (
			<PageFrame title="Event Center">
				<Container>{innerRender}</Container>
			</PageFrame>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.events;
	if (raw.data) {
		const cardInfo = raw.data.events.map(obj => {
			return {
				...obj,
				date: new Date(obj.date).toISOString().split('T')[0],
				time: obj.time.substring(0, 5)
			};
		});

		let ccKeysSorted = JSON.parse(JSON.stringify(raw.data.ccKeys));
		ccKeysSorted.sort((a, b) => {
			const name1 = a.category.concat(' ', a.gender);
			const name2 = b.category.concat(' ', b.gender);

			if (name1 < name2) {
				return -1;
			} else if (name2 < name1) {
				return 1;
			} else {
				return 0;
			}
		});

		return {
			eventCardInfo: cardInfo,
			potentialCoordinators: raw.data.potentialCoordinators,
			ccKeys: ccKeysSorted
		};
	} else return { eventCardInfo: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getEventsData: () => dispatch(eventsData()),
		deleteEvent: id => dispatch(deleteEvt(id)),
		saveNew: body => dispatch(createNewEvt(body)),
		editOld: body => dispatch(editOldEvt(body))
	};
};

function tConvert(time) {
	// Check correct time format and split into components
	time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];

	if (time.length > 1) {
		// If time format correct
		time = time.slice(1); // Remove full string match value
		time[4] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
		time[0] = +time[0] % 12 || 12; // Adjust hours
	}
	return time.join(''); // return adjusted time or original string
}
export default connect(mapStateToProps, mapDispatchToProps)(EventCenterView);
