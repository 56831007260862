import React from 'react';
import { Modal, Container, Row, Col, Form, ListGroup } from 'react-bootstrap';

export default function ManageRefSports(props) {
	if (props.refInfo === null) {
		return null;
	}

	return (
		<Modal show={true} onHide={props.cancelHandler}>
			<Modal.Header closeButton>
				<Modal.Title>{props.refInfo.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Form>
						<Row>
							<Col>
								<Form.Label column>Sports</Form.Label>
								<ListGroup>
									{props.sports.map(x => {
										return (
											<ListGroup.Item
												key={`${props.refInfo}-${x}`}
											>
												<Form.Group>
													<Form.Switch
														label={x}
														onChange={() =>
															props.changeRefSports(
																x
															)
														}
														checked={props.refInfo.sports.includes(
															x
														)}
													/>
												</Form.Group>
											</ListGroup.Item>
										);
									})}
								</ListGroup>
							</Col>
						</Row>
					</Form>
				</Container>
			</Modal.Body>
		</Modal>
	);
}
