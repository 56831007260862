import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Previous style for the span in the row
// const b2bRowStyle = { backgroundColor: '#39FF14' };

export function BackToBackRow(props) {
	return (
		<li key={`${props.location}-${props.team}-${props.past_location}`}>
			{props.location} | {props.team}{' '}
			<span>(coming from {props.past_location})</span>
		</li>
	);
}

export function BackToBackTimeslot(props) {
	return (
		<Row>
			<Col className="d-flex flex-column">
				<p>{props.timeslot}</p>
				<ul className="ms-3em">
					{props.backToBacks.map(b2bForTeam => {
						return (
							<BackToBackRow
								key={`${b2bForTeam.id}${b2bForTeam.team}`}
								{...b2bForTeam}
							/>
						);
					})}
				</ul>
			</Col>
		</Row>
	);
}
