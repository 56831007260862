import React from 'react';
import { connect } from 'react-redux';
import ToggleSelector from '../components/general/ToggleSelector.js';
import ManagePeopleBoards from '../components/logistics/ManagePeopleBoards.js';
import SingleSelectList from '../components/general/SingleSelectList.js';
import { Container } from 'react-bootstrap';
import {
	managePeopleInfo,
	addusertogroup,
	removeuserfromgroup,
	manageCoachInfo,
	changesportforref
} from '../services/managepeople/managepeopleActions.js';
import PageFrame from '../containers/app/PageFrame.js';
import ManageRefSports from '../components/managepeople/ManageRefSports.js';

class ManagePeopleView extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedUserType: 'SITEDIRECTOR',
			selectedCountry: '',
			selectedTeam: '',
			selectedRefInfo: null
		};

		this.selectUserType = this.selectUserType.bind(this);
		this.selectCountry = this.selectCountry.bind(this);
		this.selectTeam = this.selectTeam.bind(this);
		this.showRefModal = this.showRefModal.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
		this.changeRefSports = this.changeRefSports.bind(this);
	}

	componentDidMount() {
		this.props.getManagePeopleInfo();
	}

	selectUserType(event) {
		this.setState({
			selectedUserType: event.target.innerHTML,
			selectedCountry: '',
			selectedRefInfo: null
		});
	}

	selectCountry(event) {
		this.setState({
			selectedCountry: event.target.innerHTML,
			selectedTeam: ''
		});
	}

	selectTeam(team) {
		let selectedTeamID = this.props.teamsByCountry[
			this.state.selectedCountry
		].find(x => x.text === team).id;

		this.props.getManageCoachInfo(selectedTeamID);

		this.setState({
			selectedTeam: selectedTeamID
		});
	}

	async changeRefSports(sport) {
		console.log(sport);
		if (this.state.selectedRefInfo.sports.includes(sport)) {
			await this.props.changeSportForRef({
				sport: sport,
				username: this.state.selectedRefInfo.id,
				change: 'remove'
			});

			this.setState({
				selectedRefInfo: {
					id: this.state.selectedRefInfo.id,
					name: this.state.selectedRefInfo.name,
					sports: this.state.selectedRefInfo.sports.filter(
						x => x !== sport
					)
				}
			});
		} else {
			await this.props.changeSportForRef({
				sport: sport,
				username: this.state.selectedRefInfo.id,
				change: 'add'
			});

			let newArr = this.state.selectedRefInfo.sports;
			newArr.push(sport);

			this.setState({
				selectedRefInfo: {
					id: this.state.selectedRefInfo.id,
					name: this.state.selectedRefInfo.name,
					sports: newArr
				}
			});
		}
	}

	showRefModal(userId) {
		this.setState({
			selectedRefInfo: {
				id: userId,
				name: this.props.refereeInfo.selectedUsers.find(
					x => x.id === userId
				).text,
				sports: this.props.refsports
					.filter(x => x.username === userId)
					.map(x => x.sport)
			}
		});
	}

	cancelHandler() {
		this.setState({
			selectedRefInfo: null
		});
	}

	render() {
		let selectionObj = {
			selectedItem: this.state.selectedUserType,
			selectItem: this.selectUserType
		};

		let countrySelectionObj = {
			selectedItem: this.state.selectedCountry,
			selectItem: this.selectCountry
		};

		let pageRender;

		const selectedTeamStyle = {
			borderRadius: '15px',
			padding: '3px 20px',
			margin: '5px',
			cursor: 'pointer',
			backgroundColor: '#1857C3',
			color: '#fff'
		};

		switch (this.state.selectedUserType) {
			case 'COACH':
				if (this.state.selectedCountry === '') {
					pageRender = (
						<ToggleSelector
							itemsList={this.props.countries}
							selectionObj={countrySelectionObj}
						/>
					);
				} else if (
					this.state.selectedTeam === '' ||
					this.props.teamDataLoading
				) {
					pageRender = (
						<div>
							<ToggleSelector
								itemsList={this.props.countries}
								selectionObj={countrySelectionObj}
							/>
							<SingleSelectList
								itemsList={this.props.teamsByCountry[
									this.state.selectedCountry
								].map(x => x.text)}
								selectItem={this.selectTeam}
								selectedItem={this.state.selectedTeam}
								selectedItemStyle={selectedTeamStyle}
								searchable={true}
							/>
						</div>
					);
				} else {
					let currentTeam = this.props.teamsByCountry[
						this.state.selectedCountry
					].find(x => x.id === this.state.selectedTeam).text;
					pageRender = (
						<div>
							<ToggleSelector
								itemsList={this.props.countries}
								selectionObj={countrySelectionObj}
							/>
							<SingleSelectList
								itemsList={this.props.teamsByCountry[
									this.state.selectedCountry
								].map(x => x.text)}
								selectItem={this.selectTeam}
								selectedItem={currentTeam}
								selectedItemStyle={selectedTeamStyle}
								searchable={true}
							/>
							<ManagePeopleBoards
								key={`COUNTRY-${this.state.selectedCountry}`}
								unselectedSearchable={true}
								selectedSearchable={false}
								unselectedLabel="Non Coaches"
								selectedLabel="Coaches"
								selectedType="COACH"
								unselectedUsers={this.props.coachInfoByTeam[
									this.state.selectedTeam
								].potentialcoaches.map(x => {
									return { id: x.username, text: x.name };
								})}
								selectedUsers={this.props.coachInfoByTeam[
									this.state.selectedTeam
								].coaches.map(x => {
									return { id: x.username, text: x.name };
								})}
								removeUserFromGroup={this.props.removeCoach.bind(
									null,
									this.state.selectedTeam
								)}
								addUserToGroup={this.props.addCoach.bind(
									null,
									this.state.selectedTeam
								)}
							/>
						</div>
					);
				}
				break;
			case 'CAPTAIN':
				if (this.state.selectedCountry === '') {
					pageRender = (
						<ToggleSelector
							itemsList={this.props.countries}
							selectionObj={countrySelectionObj}
						/>
					);
				} else {
					pageRender = (
						<div>
							<ToggleSelector
								itemsList={this.props.countries}
								selectionObj={countrySelectionObj}
							/>
							<ManagePeopleBoards
								key={`COUNTRY-${this.state.selectedCountry}`}
								unselectedSearchable={true}
								selectedSearchable={false}
								unselectedLabel="Non Captain"
								selectedType="CAPTAIN"
								removeUserFromGroup={
									this.props.removeUserFromGroup
								}
								addUserToGroup={this.props.addCaptain.bind(
									null,
									this.state.selectedCountry
								)}
								selectedLabel={`${this.state.selectedCountry} Captain`}
								unselectedUsers={
									this.props.captainInfo.unselectedUsers
								}
								selectedUsers={
									this.props.captainInfo.captainsByCountry[
										this.state.selectedCountry
									]
								}
							/>
						</div>
					);
				}

				break;
			case 'SITEDIRECTOR':
				pageRender = (
					<ManagePeopleBoards
						key="sitedirector"
						unselectedSearchable={true}
						selectedSearchable={true}
						unselectedLabel="Non Site Directors"
						selectedLabel="Site Directors"
						selectedType="SITEDIRECTOR"
						removeUserFromGroup={this.props.removeUserFromGroup}
						addUserToGroup={this.props.addUserToGroup}
						{...this.props.siteDirectorInfo}
					/>
				);
				break;
			case 'STAFF':
				pageRender = (
					<ManagePeopleBoards
						key="staff"
						unselectedSearchable={true}
						selectedSearchable={false}
						unselectedLabel="Non Staff"
						selectedLabel="Staff"
						selectedType="STAFF"
						removeUserFromGroup={this.props.removeUserFromGroup}
						addUserToGroup={this.props.addUserToGroup}
						{...this.props.staffInfo}
					/>
				);
				break;
			case 'REFEREE':
				pageRender = (
					<ManagePeopleBoards
						key="referee"
						unselectedSearchable={true}
						selectedSearchable={false}
						unselectedLabel="Non Referees"
						selectedLabel="Referees"
						selectedType="REFEREE"
						removeUserFromGroup={this.props.removeUserFromGroup}
						addUserToGroup={this.props.addUserToGroup}
						selectedClickHandler={this.showRefModal}
						{...this.props.refereeInfo}
					/>
				);
				break;
			case 'COORDINATOR':
				pageRender = (
					<ManagePeopleBoards
						key="coordinators"
						unselectedSearchable={true}
						selectedSearchable={true}
						unselectedLabel="Non Coordinators"
						selectedLabel="Coordinators"
						selectedType="COORDINATOR"
						removeUserFromGroup={this.props.removeUserFromGroup}
						addUserToGroup={this.props.addUserToGroup}
						{...this.props.coordinatorInfo}
					/>
				);
				break;
			default:
				break;
		}

		if (this.props.countries.length === 0) {
			return <p>Loading...</p>;
		}

		return (
			<PageFrame title="Manage People">
				<ManageRefSports
					refInfo={this.state.selectedRefInfo}
					cancelHandler={this.cancelHandler}
					sports={this.props.sports}
					changeRefSports={this.changeRefSports}
				/>
				<Container>
					<ToggleSelector
						itemsList={['SITEDIRECTOR']}
						selectionObj={selectionObj}
					/>
					{pageRender}
				</Container>
			</PageFrame>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.managepeople;
	if (raw.staff) {
		let captainsList = raw.captains.map(x => {
			return {
				id: x.username,
				text: x.name,
				country: x.country
			};
		});

		let teamsList = raw.teams.map(x => {
			return {
				id: x.team_name,
				text: x.team_name,
				country: x.country
			};
		});

		let captainsByCountry = {};
		let teamsByCountry = {};

		raw.countries.forEach(x => {
			captainsByCountry[x] = captainsList.filter(y => y.country === x);
			teamsByCountry[x] = teamsList.filter(y => y.country === x);
		});

		return {
			countries: raw.countries,
			sports: raw.sports,
			refsports: raw.refsports,
			teamsByCountry: teamsByCountry,
			teamDataLoading: raw.teamDataLoading,
			coachInfoByTeam: raw.coachInfoByTeam,
			captainInfo: {
				unselectedUsers: raw.potentialcaptains.map(x => {
					return { id: x.username, text: x.name };
				}),
				captainsByCountry: captainsByCountry
			},
			refereeInfo: {
				unselectedUsers: raw.potentialreferees,
				selectedUsers: raw.referees
			},
			coordinatorInfo: {
				unselectedUsers: raw.potentialcoordinators,
				selectedUsers: raw.coordinators
			},
			siteDirectorInfo: {
				unselectedUsers: raw.potentialsitedirector,
				selectedUsers: raw.sitedirector
			},
			staffInfo: {
				unselectedUsers: raw.potentialstaff,
				selectedUsers: raw.staff
			}
		};
	} else {
		return {
			countries: [],
			teamsByCountry: {},
			captainInfo: {},
			siteDirectorInfo: {},
			staffInfo: {},
			coachInfoByTeam: {},
			refereeInfo: {},
			coordinatorInfo: {}
		};
	}
};

const mapDispatchToProps = dispatch => {
	return {
		getManagePeopleInfo: () => dispatch(managePeopleInfo()),
		addUserToGroup: (group, user) =>
			dispatch(addusertogroup({ group, user })),
		removeUserFromGroup: (group, user) =>
			dispatch(removeuserfromgroup({ group, user })),
		addCaptain: (country, group, user) => {
			user.country = country;
			dispatch(addusertogroup({ group, user }));
		},
		addCoach: (team, group, user) => {
			user.team_id = team;
			dispatch(addusertogroup({ group, user }));
		},
		removeCoach: (team, group, user) => {
			user.team_id = team;
			dispatch(removeuserfromgroup({ group, user }));
		},
		changeSportForRef: obj => {
			dispatch(changesportforref(obj));
		},
		getManageCoachInfo: team_id => dispatch(manageCoachInfo(team_id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePeopleView);
