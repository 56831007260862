import React from 'react';
import { connect } from 'react-redux';
import { ScoreChangeRequestCard } from '../components/scores/ScoreChangeRequestCard';
import {
	scorechangerequests,
	rejectscorechangerequest,
	acceptscorechangerequest
} from '../services/scorechanges/scorechangesActions';
import HorizontalDeck from '../components/HorizontalDeck';
import { Container } from 'react-bootstrap';
import PageFrame from '../containers/app/PageFrame';

class ScoreChangeRequestView extends React.Component {
	componentDidMount() {
		this.props.getScoreChangeRequests();
	}

	render() {
		if (this.props.scorechangeRequests) {
			return (
				<PageFrame title="Score Change Requests">
					<Container>
						{!this.props.scorechangeRequests[0] && (
							<h2 className="text-center">
								Hurray! No Score Change Requests so far
							</h2>
						)}
						{this.props.scorechangeRequests[0] && (
							<HorizontalDeck cardsToShow={2}>
								{this.props.scorechangeRequests.map(x => (
									<ScoreChangeRequestCard
										key={x.gameID}
										{...x}
										acceptScoreChange={
											this.props.acceptScoreChange
										}
										rejectScoreChange={
											this.props.rejectScoreChange
										}
									/>
								))}
							</HorizontalDeck>
						)}
					</Container>
				</PageFrame>
			);
		} else {
			return <p>Loading</p>;
		}
	}
}

const mapStateToProps = state => {
	let raw = state.scorechanges;
	if (raw.scores) {
		let requests = raw.scores.map(x => {
			let setScoreTop = `${x.team1_set1} - ${x.team2_set1} | ${x.team1_set2} - ${x.team2_set2}`;
			let setScoreThird =
				x.team1_set3 === null
					? ``
					: `${x.team1_set3} - ${x.team2_set3}`;

			return {
				gameID: x.game_id,
				score: `${x.score1} - ${x.score2}`,
				setScore: [setScoreTop, setScoreThird],
				scoreCardTop: {
					sport: x.sport,
					showRachmanusFlag: false,
					showTimer: false,
					timeRemaining: false,
					gameInfo: {
						datetime: `${x.date} | ${x.time}`,
						location: x.location,
						tournament: x.tournament
					}
				},
				scoreCardBottom: {
					team1Icon: {
						imageURL: `assets/countryicons/flag-${x.flag1}.png`,
						label: x.short1
					},
					team2Icon: {
						imageURL: `assets/countryicons/flag-${x.flag2}.png`,
						label: x.short2
					},
					hasSet: x.hasSet === 1,
					coaches: x.coaches
				},
				alternateScores: raw.scorechanges
					.filter(y => y.gameId === x.game_id)
					.map(z => {
						let setScoreTop = `${z.team1_set1} - ${z.team2_set1} | ${z.team1_set2} - ${z.team2_set2}`;
						let setScoreBottom =
							z.team1_set3 === null
								? ``
								: ` | ${z.team1_set3} - ${z.team2_set3}`;

						return {
							...z,
							numReports: z.count,
							score: `${z.score1} - ${z.score2}`,
							setScore: setScoreTop + setScoreBottom
						};
					})
			};
		});

		return {
			scorechangeRequests: requests
		};
	} else return { scorechangeRequests: [] };
};

const mapDispatchToProps = dispatch => {
	return {
		getScoreChangeRequests: () => dispatch(scorechangerequests()),
		acceptScoreChange: obj => {
			let reason = window.prompt('Enter a reason for accepting');
			if (reason !== null) {
				dispatch(
					acceptscorechangerequest({
						...obj,
						reason: reason
					})
				);
			}
		},
		rejectScoreChange: obj => dispatch(rejectscorechangerequest(obj))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScoreChangeRequestView);
