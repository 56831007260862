import React from 'react';
import ToggleSelector from '../components/general/ToggleSelector.js';
import AddPoints from '../components/events/AddPoints.js';
import GenericTable from '../components/general/GenericTable.js';
import { connect } from 'react-redux';
import {
	awardpointsviewinfo,
	pointsbyevent,
	addpointstoevent,
	deletepoints
} from '../services/medals/awardmedalsActions.js';
import { Container, Col } from 'react-bootstrap';
import PageFrame from '../containers/app/PageFrame.js';
const d3 = require('d3-array');

/**
 * @typedef {Object} Event - represents an event in the tournament
 * @property {string} title - title of the event
 * @property {int} event_id - unique identifier for the event
 * @property {string} date - date of the event
 * @property {string} time - time of the event
 * @property {string} location - location of the event
 * @property {string} duration - duration of the event
 */

/**
 * @typedef {Object} Country - represents a country participating in
 * the tournament
 * @property {string} short_name - abbreviated name for the country
 * @property {string} long_name - full name for the country
 */

/**
 * @typedef {Object} Point - represents points awarded to a player
 * or country in a specific event
 * @property {int} event_id - unique identifier for the event
 * @property {int} points_id - unique identifier for the point
 * @property {string} country - name of the country
 * @property {string} awardee - name of the player who was awarded
 * the points (if applicable)
 * @property {string} points_description - reason for awarding the points
 * @property {int} points - number of points awarded
 */

/**
 * @typedef {Object} PointInput - represents the information required
 * to award a point
 * @property {int} event_id - unique identifier for the event
 * @property {string} short_name - abbreviated name for the country receiving
 * points
 * @property {boolean} awardee_type - true if the awardee is a person, false
 * if awardee is a country
 * @property {string} awardee - name of person who received the points, if
 * applicable
 * @property {string} points_description - reason for awarding points
 * @property {int} num_points - number of points being awarded
 */

/**
 * @class AwardPointsView
 * @classdesc This component allows coordinators to give points to different
 * countries or players for each event
 * @param {Object} props
 * @param {function} props.awardPointsViewInfo - gets list of all events
 * the current user is a coordinator for, as well as a list of countries. REDUX
 * @param {Event[]} props.events - list of all the {@link Event events} that the current
 * user is a coordinator for.
 * @param {function} props.getPointsByEvent - gets all the points that have been awarded
 * for a specific event. Takes in one argument, the event_id.
 * @param {function} props.addPointsToEvent - awards a point for a specific event.
 * Takes in one argument, a {@link PointInput}
 * @param {Point[]} props.points - list of all the {@link Point points} that have been scored
 * @param {Country[]} props.countries - list of all the {@link Country countries} participating in the
 * Maccabi Games
 * @returns {React.Component}
 */
class AwardMedalsView extends React.Component {
	constructor() {
		super();
		this.state = {
			event: '',
			country: '',
			awardeeType: '',
			awardee: '',
			pointsDescription: '',
			points: '0',
			serverError: false,
			hasEvents: false,
			dataArrived: false,
			medalType: ''
		};

		this.selectEvent = this.selectEvent.bind(this);
		this.selectCountry = this.selectCountry.bind(this);
		this.selectAwardeeType = this.selectAwardeeType.bind(this);
		this.selectMedalType = this.selectMedalType.bind(this);
		this.changeAwardee = this.changeAwardee.bind(this);
		this.changePointsDesc = this.changePointsDesc.bind(this);
		this.addPoints = this.addPoints.bind(this);
		this.deletePoint = this.deletePoint.bind(this);
	}

	async componentDidMount() {
		this.props
			.awardPointsViewInfo()
			.then(() => {
				if (this.props.events.length === 0) {
					this.setState({ hasEvents: false, dataArrived: true });
				} else {
					this.setState({
						hasEvents: true,
						event: this.props.events[0].title,
						country: this.props.countries[0].long_name,
						awardeeType: 'Person',
						dataArrived: true,
						medalType: 'Gold'
					});

					this.props.events.forEach(event => {
						this.props.getPointsByEvent(event.event_id);
					});
				}
			})
			.catch(err => {
				console.log(err);
				// alert('Internal Server Error. Please Try Again')
				this.setState({ serverError: true });
			});
	}

	selectEvent(e) {
		this.setState({
			event: e.target.textContent,
			country: this.props.countries[0].long_name,
			awardeeType: 'Person',
			awardee: '',
			pointsDescription: '',
			points: 0,
			medalType: 'Gold'
		});
	}

	selectCountry(event) {
		let response = event.target.innerHTML;
		if (this.state.awardeeType === 'Country') {
			this.setState({
				country: response,
				awardee: response
			});
		} else {
			this.setState({
				country: response
			});
		}
	}

	selectAwardeeType(e) {
		console.log('Awardee Type', e.target.innerHTML);
		if (e.target.innerHTML === 'Person')
			this.setState({
				awardeeType: e.target.innerHTML,
				awardee: ''
			});
		else
			this.setState({
				awardeeType: e.target.innerHTML,
				awardee: this.state.country
			});
	}

	selectMedalType(e) {
		console.log('Value is medal', e.target.innerHTML);
		if (e.target.value === 'Gold')
			this.setState({
				medalType: e.target.innerHTML
			});
		else
			this.setState({
				medalType: e.target.innerHTML
			});
	}

	changeAwardee(e) {
		this.setState({ awardee: e.target.value });
	}

	changePointsDesc(e) {
		this.setState({ pointsDescription: e.target.value });
	}

	changePoints(e) {
		this.setState({ points: e.target.value });
	}

	// Need to udpate this still
	addPoints() {
		let pointsInfo = {
			event_id: this.props.events.find(x => x.title === this.state.event)
				.event_id,
			short_name: this.props.countries.find(
				x => x.long_name === this.state.country
			).short_name,
			awardee_type: this.state.awardeeType === 'Person',
			awardee: this.state.awardee,
			points_description: this.state.pointsDescription,
			num_points: this.state.medalType === 'Gold' ? 2 : 1
		};

		const awardeeValid =
			this.state.awardee.trim() && this.state.awardee.length <= 50;
		const pointsDescValid =
			this.state.pointsDescription.trim() &&
			this.state.pointsDescription.length <= 100;
		const numericPoints = parseFloat(pointsInfo.num_points);
		const pointsValid =
			Number.isInteger(numericPoints) && numericPoints > 0;
		const canSendRequest = this.props.addPointsRequestStatus !== 'pending';

		let alertMessage = '';
		if (!awardeeValid)
			alertMessage +=
				'Name must be less than 50 characters and non-empty\n';
		if (!pointsDescValid)
			alertMessage +=
				'Points description must be less than 100 characters and non-empty\n';
		if (!pointsValid) alertMessage += 'Points must be a positive integer\n';
		if (!canSendRequest)
			alertMessage +=
				'Previous request is still pending. Fields will clear if successful';

		if (awardeeValid && pointsDescValid && pointsValid && canSendRequest) {
			this.props.addPointsToEvent(pointsInfo).then(res => {
				if (res.type === addpointstoevent.fulfilled.type) {
					this.setState({
						awardee:
							this.state.awardeeType === 'Country'
								? this.state.awardee
								: '',
						pointsDescription: '',
						points: '0'
					});
				} else if (res.type === addpointstoevent.rejected.type) {
					console.log(res);
					alert('Server Error with Request. Please try again');
				}
			});
		} else alert(alertMessage);
	}

	deletePoint(point_id) {
		this.props.deletePoint(point_id).then(res => {
			if (res.type === deletepoints.rejected.type) {
				console.log(res);
				alert('Server Error with Request. Please try again');
			}
		});
	}

	render() {
		if (this.state.serverError) {
			return <p>Internal Server Error. Please Try Again</p>;
		} else if (!this.state.dataArrived) {
			return <p>Loading...</p>;
		} else if (!this.state.hasEvents) {
			return <p>You have not yet been assigned to any events</p>;
		} else if (this.props.events) {
			let eventSelectionProps = {
				itemsList: this.props.events.map(x => x.title),
				selectionObj: {
					selectedItem: this.state.event,
					selectItem: this.selectEvent,
					selectedItemStyle: {}
				}
			};

			let countrySelectionProps = {
				itemsList: this.props.countries.map(x => x.long_name),
				selectionObj: {
					selectedItem: this.state.country,
					selectItem: this.selectCountry
				}
			};

			let addPointsProps = {
				awardeeType: this.state.awardeeType,
				selectAwardeeType: this.selectAwardeeType,
				awardee: this.state.awardee,
				changeAwardee: this.changeAwardee,
				pointsDesc: this.state.pointsDescription,
				changePointsDesc: this.changePointsDesc,
				addPoints: this.addPoints,
				country: this.state.country,
				medalType: this.state.medalType,
				selectMedalType: this.selectMedalType
			};

			const tableDiv = {
				marginBottom: '30px',
				// color: '#5CAEFA',
				backgroundColor: '#e8e8e8',
				padding: '2vh 0'
			};

			const tableStyle = {
				width: '80%',
				margin: 'auto auto',
				padding: '60px',
				textAlign: 'right'
			};

			const xStyle = {
				maxHeight: '.8vw',
				cursor: 'pointer'
			};

			let currentEvent = this.props.events.find(
				x => x.title === this.state.event
			);
			let PointsTableRender = null;
			if (
				currentEvent &&
				this.props.pointsByEvent &&
				this.props.pointsByEvent[currentEvent.event_id]
			) {
				let currentEventPoints =
					this.props.pointsByEvent[currentEvent.event_id];
				let pointsTableHeaders = Object.keys(currentEventPoints[0]).map(
					x => {
						let displayStr = x
							.split('_')
							.map(x => x.charAt(0).toUpperCase() + x.slice(1))
							.join(' ');
						return {
							value: x,
							text: displayStr
						};
					}
				);
				pointsTableHeaders.push({ value: 'edit', text: 'X' });
				let columnsToHide = ['points_id', 'event_id', 'canDelete'];
				let pointsTableData = currentEventPoints.map(x => {
					//Hook up to delete point action
					let deleteButton = null;
					if (x.canDelete === 1) {
						deleteButton = (
							<img
								onClick={this.deletePoint.bind(
									null,
									x.points_id
								)}
								style={xStyle}
								alt="red x"
								src="assets/icons/redX.png"
							></img>
						);
					}
					return { ...x, key: x.points_id, edit: deleteButton };
				});
				PointsTableRender = (
					<GenericTable
						headers={pointsTableHeaders}
						colsToHide={columnsToHide}
						style={tableStyle}
						data={pointsTableData}
					/>
				);
			}

			let eventHeaderRender = null;
			if (currentEvent) {
				const boldStyle = {
					fontWeight: '700',
					fontSize: '125%'
				};

				eventHeaderRender = (
					<div className="m-2">
						<p style={boldStyle}>Currently adding points to:</p>
						<p>
							<strong>Event:</strong> {currentEvent.title}
						</p>
						<p>
							<strong>Date:</strong> {currentEvent.date}
						</p>
						<p>
							<strong>Time:</strong> {currentEvent.time}
						</p>
						<p>
							<strong>Location:</strong> {currentEvent.location}
						</p>
						<p>
							<strong>Duration:</strong> {currentEvent.duration}
						</p>
					</div>
				);
			}

			const toggleStyle = {
				display: 'flex',
				width: '100%'
			};

			const itemStyle = {
				width: '33vw',
				borderLeft: '0.5px solid white',
				borderRight: '0.5px solid white'
			};

			const stepsStyle = {
				fontSize: '150%'
			};

			return (
				<PageFrame title="Award Medals">
					<Container>
						<Col>
							<p style={stepsStyle}>Select Event</p>
							<ToggleSelector
								itemStyle={itemStyle}
								customStyle={toggleStyle}
								{...eventSelectionProps}
							/>
							<p style={stepsStyle}>
								Select a Country and Assign Medals
							</p>
							<Col>
								<ToggleSelector {...countrySelectionProps} />
								{/* <SingleSelectList customStyle={countriesStyle} {...countrySelectionProps} searchable={false}/> */}
								{eventHeaderRender}
								<AddPoints {...addPointsProps} />
							</Col>
							<Col>
								<p style={stepsStyle}>
									Successfully added Medals for this event:
								</p>
								<div style={tableDiv}>{PointsTableRender}</div>
							</Col>
						</Col>
					</Container>
				</PageFrame>
			);
		} else {
			return <p>Error</p>;
		}
	}
}

const mapStateToProps = state => {
	if (state.awardPoints) {
		let pointsByEvent = null;
		if (state.awardPoints.points) {
			pointsByEvent = Object.fromEntries(
				d3.group(state.awardPoints.points, x => x.event_id)
			);
		}
		return {
			events: state.awardPoints.events,
			countries: state.awardPoints.countries,
			pointsByEvent: pointsByEvent,
			addPointsRequestStatus: state.awardPoints.addPointsRequestStatus
		};
	} else
		return {
			events: null,
			countries: null,
			pointsByEvent: null,
			addPointsRequestStatus: null
		};
};

const mapDispatchToProps = dispatch => {
	return {
		awardPointsViewInfo: () => dispatch(awardpointsviewinfo()),
		getPointsByEvent: event_id => dispatch(pointsbyevent(event_id)),
		addPointsToEvent: pointInfo => dispatch(addpointstoevent(pointInfo)),
		deletePoint: point_id => dispatch(deletepoints(point_id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AwardMedalsView);
