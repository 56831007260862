import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export default function DateSelector(props) {
	return (
		<DropdownButton title={props.title}>
			{props.dates.map(x => {
				return (
					<Dropdown.Item
						key={x.rawDate}
						onClick={props.selectDate.bind(null, x.rawDate)}
					>
						{x.formatDate}
					</Dropdown.Item>
				);
			})}
		</DropdownButton>
	);
}
