import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const backtobackgames = createAsyncThunk('GET_B2B', getBackToBack);
export const backtobackdates = createAsyncThunk(
	'GET_DATES_B2B',
	getBackToBackDates
);

async function getBackToBack(date) {
	try {
		const result = await request(`schedule/backtoback?date=${date}`);
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.error(err);
		return Promise.reject(err);
	}
}

async function getBackToBackDates() {
	try {
		const result = await request(`schedule/dates`);
		const output = result.data.map(x => {
			return {
				formatDate: x.date,
				rawDate: x.rawDate.split('T')[0]
			};
		});
		return Promise.resolve(output);
	} catch (err) {
		console.error(err);
		return Promise.reject(err);
	}
}
