import React from 'react';
import { connect } from 'react-redux';
import { Row, Container } from 'react-bootstrap';
import { untieData, updateUntie } from '../services/untie/untieAction.js';
import UntieCard from '../components/untie/UntieCard';
import PageFrame from '../containers/app/PageFrame.js';
class UntieTeamsView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tiedTeams: []
		};

		this.rankTeam = this.rankTeam.bind(this);
		this.saveRank = this.saveRank.bind(this);
	}

	rankTeam(tournament, index, position, indexTournament) {
		console.log('Index', index);
		console.log('Position', position);
		console.log('redoing tournament', tournament);
		console.log('indexTournament', indexTournament);
		console.log('this.state.tiedTeams', this.state.tiedTeams);
		let positionChange = 0;
		if (position === 'up') {
			positionChange = index - 1;
		} else {
			positionChange = index + 1;
		}
		if (positionChange < 0) {
			// console.log("Moving top (negative)")
			return;
		}
		let sortedArray = this.array_move(
			this.state.tiedTeams[indexTournament],
			index,
			positionChange
		);
		// console.log("sortedArray", sortedArray)
		let smallArray = [...this.state.tiedTeams];
		smallArray[indexTournament] = sortedArray;
		// console.log("smallArray", smallArray)
		this.setState({ tiedTeams: smallArray });
	}

	array_move(arr, old_index, new_index) {
		let arrayUsed = [...arr];
		// console.log("Array", arrayUsed)
		// console.log("old_index", old_index)
		// console.log("new_index", new_index)
		var element = arrayUsed[old_index];
		arrayUsed.splice(old_index, 1);
		arrayUsed.splice(new_index, 0, element);
		return arrayUsed;
		// if (new_index >= arr.length) {
		// 	var k = new_index - arr.length + 1;
		// 	while (k--) {
		// 		arr.push(undefined);
		// 	}
		// }
		// console.log("arr.splice(old_index, 1)[0]", arr.splice(old_index, 1))
		// arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		// return arr; // for testing
	}

	saveRank(indexTournament) {
		console.log('Index Tournament', indexTournament);
		let savedTeam = this.state.tiedTeams[indexTournament];
		console.log('Saved Team', savedTeam);
		this.props.postUntie({ tied: savedTeam }).then(() => {
			this.props.getUntie();
		});
	}

	componentDidMount() {
		this.props.getUntie().then(tied => {
			console.log('Untie Then', tied);
			this.setState({ tiedTeams: tied.payload });
		});
	}
	render() {
		console.log('Untie Teams props', this.props);
		return (
			<PageFrame title="Untie Teams">
				<Container>
					<Row xs={2} md={4} className="g-4">
						{this.state.tiedTeams &&
							this.state.tiedTeams.map(
								(untieInfo, indexTournamentNumber) => (
									<UntieCard
										edit={true}
										info={untieInfo}
										rankTeam={this.rankTeam}
										indexTournament={indexTournamentNumber}
										saveRank={this.saveRank}
									/>
								)
							)}
					</Row>
				</Container>
			</PageFrame>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.untie.tiedTeams;
	return { untie: raw };
};

const mapDispatchToProps = dispatch => {
	return {
		getUntie: () => dispatch(untieData()),
		postUntie: obj => dispatch(updateUntie(obj))
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(UntieTeamsView);
