import React from 'react';
import { connect } from 'react-redux';
import { masterschedulechangelog } from '../services/masterschedulechanges/masterschedulechangesAction';
import { Container } from 'react-bootstrap';
import MasterScheduleInfoRow from '../components/schedule/masterschedulechanges/MasterScheduleInfoRow';
import PageFrame from '../containers/app/PageFrame';
import { DynamicTableHead } from '../components/general/DynamicTable';

const ColumnNames = [
	'Change Time',
	'Game ID',
	'Tournament',
	'Team 1 vs. Team 2',
	'Old Date',
	'Old Time',
	'Old Location',
	'New Date',
	'New Time',
	'New Location',
	'Reason',
	'Change Type'
];

class MasterScheduleChangesView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			masterSchedule: []
		};
	}
	componentDidMount() {
		this.props.getMasterScheduleChanges().then(() => {
			this.setState({ masterSchedule: this.props.masterscheduleChanges });
		});
	}

	render() {
		return (
			<PageFrame title="Master Schedule Changes">
				<Container>
					<table className="table table-striped">
						<DynamicTableHead data={ColumnNames} />
						<tbody>
							{this.state.masterSchedule.map(x => (
								<MasterScheduleInfoRow
									key={x.change_id}
									{...x}
								/>
							))}
						</tbody>
					</table>
				</Container>
			</PageFrame>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.masterschedulechanges;
	if (raw.masterschedulechangelog) {
		return {
			masterscheduleChanges: raw.masterschedulechangelog
		};
	} else return { masterscheduleChanges: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getMasterScheduleChanges: () => dispatch(masterschedulechangelog())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MasterScheduleChangesView);
