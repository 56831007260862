import React from 'react';
import { connect } from 'react-redux';
import {
	backtobackgames,
	backtobackdates
} from '../services/schedule/backtobackActions.js';
import { Container, Col, Row } from 'react-bootstrap';
import PageFrame from '../containers/app/PageFrame.js';
import DateSelector from '../components/backtoback/DateSelectorDropdown.js';
import { BackToBackTimeslot } from '../components/backtoback/BackToBackComponents.js';

class BackToBackView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDate: 'Date'
		};

		this.selectDate = this.selectDate.bind(this);
	}

	componentDidMount() {
		this.props.getDates();
	}

	async selectDate(date) {
		this.setState({
			selectedDate: date
		});

		this.props.getB2B(date);
	}

	render() {
		const DataForSelectedDateIsHere = this.props.backtoback.hasOwnProperty(
			this.state.selectedDate
		);

		let backToBackRender;
		if (this.state.selectedDate === 'Date') {
			backToBackRender = <p>Please select a date</p>;
		}

		if (this.props.backtoback.requestStatus === 'pending') {
			backToBackRender = <p>Loading...</p>;
		}

		if (DataForSelectedDateIsHere) {
			let selectedB2Bs = this.props.backtoback[this.state.selectedDate];
			const NoBackToBackGamesForSelectedDate =
				Object.keys(selectedB2Bs).length === 0;
			if (NoBackToBackGamesForSelectedDate) {
				backToBackRender = (
					<p>No back to back games for the selected date</p>
				);
			} else {
				backToBackRender = Object.keys(selectedB2Bs).map(timeslot => {
					return (
						<BackToBackTimeslot
							key={`${this.state.selectedDate}-${timeslot}`}
							backToBacks={selectedB2Bs[timeslot]}
							timeslot={timeslot}
						/>
					);
				});
			}
		}

		let selectedDateFormatted = 'Date';
		if (this.state.selectedDate !== 'Date') {
			selectedDateFormatted = this.props.dates.find(
				y => y.rawDate === this.state.selectedDate
			).formatDate;
		}

		return (
			<PageFrame title="Back-to-Back Games">
				<Container>
					<Row>
						<DateSelector
							id="dropdown-basic-button"
							title={selectedDateFormatted}
							dates={this.props.dates}
							selectDate={this.selectDate}
						/>
						<Col className="d-flex">
							<p className="mx-auto">{selectedDateFormatted}</p>
						</Col>
					</Row>
					{backToBackRender}
				</Container>
			</PageFrame>
		);
	}
}

const mapStateToProps = state => {
	let outObj = {
		requestStatus: state.backtoback.requestStatus
	};
	if (Object.keys(state.backtoback).length > 1) {
		outObj.backtoback = state.backtoback;
	} else outObj.backtoback = {};

	if (state.backtoback.dates) {
		outObj.dates = state.backtoback.dates;
	} else outObj.dates = [];

	return outObj;
};

const mapDispatchToProps = dispatch => {
	return {
		getDates: () => dispatch(backtobackdates()),
		getB2B: date => dispatch(backtobackgames(date))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BackToBackView);
