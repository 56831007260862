import React from 'react';
import { DynamicTableView } from '../components/general/DynamicTable';
import { connect } from 'react-redux';
import { redyellowcards } from '../services/redyellowcard/redyellowcardActions';
import { Container } from 'react-bootstrap';
import PageFrame from '../containers/app/PageFrame';
class RedYellowCardView extends React.Component {
	componentDidMount() {
		this.props.getCardInfo();
	}

	render() {
		if (this.props.redyellowCards) {
			return (
				<PageFrame title="Red/Yellow Card">
					<Container>
						<DynamicTableView
							data={this.props.redyellowCards}
							name="redYellowCards"
						/>
					</Container>
				</PageFrame>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.redyellow;
	if (raw.cards) {
		return {
			redyellowCards: raw.cards.map(cards => {
				return {
					...cards,
					timestamp: new Date(cards.timestamp).toLocaleString()
				};
			})
		};
	} else return { redyellowCards: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getCardInfo: () => dispatch(redyellowcards())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RedYellowCardView);
