import React from 'react';
import { connect } from 'react-redux';
import Faq from 'react-faq-component';
import { faqData } from '../services/faq/faqActions.js';
import CategoryPanel from './../components/faq/CategoryPanel';
import { Container, Col } from 'react-bootstrap';
import PageFrame from '../containers/app/PageFrame.js';

const stylesFAQ = {
	// bgColor: 'white',
	titleTextColor: 'black',
	rowTitleColor: 'black'
	// rowContentColor: 'grey',
	// arrowColor: "red",
};

const config = {
	// animate: true,
	// arrowIcon: "V",
	// tabFocus: true
};

class FAQView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			categories: [],
			chosenFilter: 'All',
			allItems: { rows: [], title: 'Maccabi Games FAQ' }
		};
		this.showFilteredItems = this.showFilteredItems.bind(this);
	}
	componentDidMount() {
		this.props.getFAQData().then(items => {
			this.setState({
				allItems: { rows: items.payload, title: 'Maccabi Games FAQ' }
			});
			let categories = [
				...new Set(
					items.payload.map(item => {
						return item.category;
					})
				)
			];
			// console.log("FAQ Data", categories)
			this.setState({ categories });
		});
	}
	showFilteredItems(event) {
		// console.log("Filtering Items", heading)
		let heading = event.target.innerHTML;
		// console.log("All Items", this.props.faqList)
		let items = this.props.faqList.rows.filter(x => {
			if (heading === 'All') return true;
			return x.category === heading;
		});
		let insertedItems = { rows: items, title: 'Maccabi Games FAQ' };
		this.setState({ chosenFilter: heading, allItems: insertedItems });
	}

	render() {
		let selectionObj = {
			selectedItem: this.state.heading,
			selectItem: this.showFilteredItems
		};
		// console.log("Items are", this.state.allItems)
		return (
			<PageFrame title="FAQ">
				<Container>
					<Col>
						<CategoryPanel
							categories={this.state.categories}
							selectionObj={selectionObj}
						/>
					</Col>
					<Col>
						<Faq
							data={this.state.allItems}
							styles={stylesFAQ}
							config={config}
						/>
					</Col>
				</Container>
			</PageFrame>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.faq;
	if (raw.data) {
		return {
			faqList: { title: 'Maccabi Games FAQ', rows: raw.data },
			faqStatus: raw.requestStatus
		};
	} else return { eventCardInfo: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getFAQData: () => dispatch(faqData())
		// deleteEvent: (id) => dispatch(deleteEvt(id)),
		// saveNew: (body) => dispatch(createNewEvt(body)),
		// editOld: (body) => dispatch(editOldEvt(body))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQView);
