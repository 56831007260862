import React from 'react';
import { Button } from 'react-bootstrap';
import PageFrame from '../containers/app/PageFrame';

export default function NewFeatReqView(props) {
	return (
		<PageFrame title="New Feature Request">
			<Button href="mailto:josephl@maccabi.org?subject=New Feature Request">
				Submit New Feature Request
			</Button>
		</PageFrame>
	);
}
