import React from 'react';
import { Card } from 'react-bootstrap';

const HeaderStyle = {
	fontSize: 30,
	fontWeight: '500',
	color: '#4E73DF'
};

export default function PageFrame(props) {
	return (
		<div>
			<Card>
				<Card.Header style={HeaderStyle}>{props.title}</Card.Header>
				<Card.Body>{props.children}</Card.Body>
			</Card>
		</div>
	);
}
