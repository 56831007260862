import React from 'react';
import { connect } from 'react-redux';
import {
	broadcastpermissions,
	getrecipients,
	broadcast
} from '../services/broadcast/broadcastActions.js';
import ToggleSelector from '../components/general/ToggleSelector';
import SearchableDropdown from '../components/general/SearchableDropdown';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import PageFrame from '../containers/app/PageFrame.js';

class BroadcastView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedAudience: '',
			selectedOption: '',
			numRecipients: '',
			message: '',
			selectedTimeframe: 'Immediately',
			selectedDate: '',
			selectedTime: ''
		};

		this.selectAudience = this.selectAudience.bind(this);
		this.selectOption = this.selectOption.bind(this);
		this.updateMessage = this.updateMessage.bind(this);
		this.selectToggle = this.selectToggle.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
		this.selectDate = this.selectDate.bind(this);
		this.selectTime = this.selectTime.bind(this);
	}

	componentDidMount() {
		this.props.getBroadcastPermissions().then(() => {
			let audience = this.props.audience[0];
			let option = this.props.optionsByAudience[audience][0].id;
			this.setState({
				selectedAudience: audience,
				selectedOption: option,
				message: '',
				selectedTimeframe: 'Immediately',
				commentStatus: 'Disabled'
			});
			this.props.getRecipients({ audience: audience, option: option });
		});
	}

	selectAudience(event) {
		let audience = event.target.innerHTML;
		let option = this.props.optionsByAudience[audience][0].id;
		this.setState({
			selectedAudience: audience,
			selectedOption: option,
			message: '',
			selectedTimeframe: 'Immediately',
			commentStatus: 'Disabled'
		});
		this.props.getRecipients({ audience: audience, option: option });
	}

	selectOption(option) {
		this.setState({
			selectedOption: option,
			message: '',
			selectedTimeframe: 'Immediately',
			selectedDate: '',
			commentStatus: 'Disabled'
		});
		this.props.getRecipients({
			audience: this.state.selectedAudience,
			option: option
		});
	}

	updateMessage(event) {
		this.setState({
			message: event.target.value
		});
	}

	selectToggle(key, event) {
		this.setState({
			[key]: event.target.innerHTML
		});
	}

	selectDate(event) {
		this.setState({
			selectedDate: event.target.value
		});
	}

	selectTime(event) {
		this.setState({
			selectedTime: event.target.value
		});
	}

	sendMessage() {
		let output = Object.assign({}, this.state);

		this.props.sendBroadcast(output).then(() => {
			this.setState({
				message: '',
				selectedTimeframe: 'Immediately',
				commentStatus: 'Disabled'
			});
		});
	}

	render() {
		let numRecipientsRender = null;
		if (this.props.numRecipients !== '') {
			numRecipientsRender = (
				<p>Sending to {this.props.numRecipients} users</p>
			);
		}

		const labelStyle = {
			color: '#9B9BA3'
		};

		if (this.props.audience && this.state.selectedAudience !== '') {
			return (
				<PageFrame title="Broadcast">
					<Container>
						<Col>
							<Form>
								<Form.Group className="mb-3">
									<ToggleSelector
										itemsList={this.props.audience}
										selectionObj={{
											selectedItem:
												this.state.selectedAudience,
											selectItem: this.selectAudience
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<SearchableDropdown
										itemsList={
											this.props.optionsByAudience[
												this.state.selectedAudience
											]
										}
										selectedItem={this.state.selectedOption}
										selectItem={this.selectOption}
										searchable={
											this.state.selectedAudience ===
											'TEAM'
										}
									/>
									<Form.Text id="passwordHelpBlock" muted>
										{numRecipientsRender}
									</Form.Text>
								</Form.Group>

								<Form.Group
									className="mb-3"
									controlId="exampleForm.ControlTextarea1"
								>
									<Form.Label style={labelStyle}>
										Post Message
									</Form.Label>
									<Form.Control
										as="textarea"
										value={this.state.message}
										onChange={this.updateMessage}
										rows={3}
									/>
								</Form.Group>
								<Row className="mb-3">
									<Col>
										<Form.Group className="mb-3">
											<Form.Label style={labelStyle}>
												Allow Comments
											</Form.Label>
											<ToggleSelector
												itemsList={[
													'Allowed',
													'Disabled'
												]}
												selectionObj={{
													selectedItem:
														this.state
															.commentStatus,
													selectItem:
														this.selectToggle.bind(
															null,
															'commentStatus'
														)
												}}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group className="d-flex justify-content-center mb-3">
									<Button
										onClick={
											this.props.broadcastStatus ===
											'fulfilled'
												? this.sendMessage
												: null
										}
									>
										Send Message
									</Button>
								</Form.Group>
							</Form>
						</Col>
					</Container>
				</PageFrame>
			);
		} else {
			return <p>Loading...</p>;
		}
	}
}

const mapStateToProps = state => {
	let raw = state.broadcast;
	const optionsMapper = x => {
		return {
			id: x.id,
			text: x.displayName
		};
	};

	if (raw.audience) {
		return {
			audience: raw.audience,
			optionsByAudience: {
				MASTER: raw.MASTER ? raw.MASTER.map(optionsMapper) : null,
				COUNTRY: raw.COUNTRY ? raw.COUNTRY.map(optionsMapper) : null,
				TEAM: raw.TEAM ? raw.TEAM.map(optionsMapper) : null,
				CATEGORY: raw.CATEGORY ? raw.CATEGORY.map(optionsMapper) : null
			},
			numRecipients: raw.numRecipients,
			broadcastStatus: raw.requestStatus,
			availableDates: raw.days,
			availableTimes: raw.times
		};
	} else {
		return {
			audiences: null,
			optionsByAudience: {},
			numRecipients: '',
			broadcastStatus: 'fulfilled'
		};
	}
};

const mapDispatchToProps = dispatch => {
	return {
		getBroadcastPermissions: () => dispatch(broadcastpermissions()),
		getRecipients: obj => dispatch(getrecipients(obj)),
		sendBroadcast: obj => dispatch(broadcast(obj))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastView);
