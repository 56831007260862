import React from 'react';

export class DynamicTableHead extends React.Component {
	capitalize(text) {
		return text[0].toUpperCase() + text.slice(1);
	}

	formatText(text) {
		let arr = text.split('_');
		arr = arr.map(x => this.capitalize(x));
		return arr.join(' ');
	}

	render() {
		return (
			<thead className="thead-dark">
				<tr>
					{this.props.data.map((x, i) => (
						<th key={`${this.props.name}Head${i}`}>
							{this.formatText(x)}
						</th>
					))}
				</tr>
			</thead>
		);
	}
}

class DynamicTableRow extends React.Component {
	render() {
		const style = {
			textAlign: 'center'
		};

		return (
			<tr>
				{this.props.titles.map(x => (
					<td style={style}>{this.props.data[x]}</td>
				))}
			</tr>
		);
	}
}

export function DynamicTableView(props) {
	let headRender;
	let titleArr;

	if (props.titles) {
		titleArr = props.titles;
		headRender = <DynamicTableHead data={titleArr} name={props.name} />;
	} else if (props.data.length > 0) {
		const representativeObject = props.data[0];
		console.log(representativeObject);
		titleArr = Object.keys(representativeObject);
		headRender = <DynamicTableHead data={titleArr} name={props.name} />;
	}

	let divStyle = {
		maxWidth: '100vw',
		overflowX: 'scroll'
	};

	return (
		<div style={divStyle}>
			<table
				className="table table-striped table-bordered"
				id={props.name}
			>
				{headRender}
				<tbody>
					{props.data.map((x, i) => (
						<DynamicTableRow
							key={`${props.name}Row${i}`}
							titles={titleArr}
							data={x}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
}

// class TableFilter extends React.Component {
// 	render() {
// 		const categoryList = this.props.options.map(x => x[this.props.filterCategory]);
// 		let options = new Set(categoryList);
// 		options = [...options];

// 		return (
// 			<select onChange={this.props.choose}>
// 				<option>select</option>
// 				{options.map((x,i) => <option key={`category${i}`}>{x}</option>)}
// 			</select>
// 		)
// 	}
// }

// class DynamicTableFilterView extends React.Component {
// 	constructor() {
// 		super();
// 		this.state = {
// 			category: 'select'
// 		};
// 		this.chooseCategory = this.chooseCategory.bind(this);
// 	}

// 	chooseCategory(event) { this.setState({ category: event.target.value }) }

// 	filterData() {
// 		let rawData = this.props.data;

// 		if (this.state.category !== 'select') {
// 			return rawData.filter(x => x[this.props.filterCategory] === this.state.category);
// 		} else return [];
// 	}

// 	render() {
// 		const representativeObject = this.props.data[0];
// 		let titleArr = Object.keys(representativeObject);
// 		const filteredData = this.filterData();

// 		let headRender = null;
// 		if (filteredData.length !== 0) {
// 			headRender = <DynamicTableHead data={titleArr} name={this.props.name}/>;
// 		}

// 		return (
// 			<div>
// 				<TableFilter
// 					choose={this.chooseCategory}
// 					options={this.props.data}
// 					filterCategory={this.props.filterCategory}/>
// 				<table className="table table-striped table-bordered">
// 					{headRender}
// 					<tbody>
// 					{filteredData.map((x,i) => <DynamicTableRow key={`${this.props.name}Row${i}`} titles={titleArr} data={x}/>)}
// 					</tbody>
// 				</table>
// 			</div>
// 		)
// 	}
// }

// class DynamicTableFilterHeadView extends React.Component {
// 	constructor() {
// 		super();
// 		this.state = {
// 			category: 'select'
// 		};
// 		this.chooseCategory = this.chooseCategory.bind(this);
// 	}

// 	chooseCategory(event) { this.setState({ category: event.target.value }) }

// 	filterData() {
// 		let rawData = this.props.data;

// 		if (this.state.category !== 'select') {
// 			return rawData.filter(x => x[this.props.filterCategory] === this.state.category);
// 		} else return rawData;
// 	}

// 	render() {
// 		const filteredData = this.filterData();
// 		const titleArr = this.props.head;
// 		const headRender = <DynamicTableHead data={titleArr} name={this.props.name}/>;

// 		return (
// 			<div>
// 				<TableFilter
// 					choose={this.chooseCategory}
// 					options={this.props.data}
// 					filterCategory={this.props.filterCategory}/>
// 				<table className="table table-striped table-bordered">
// 					{headRender}
// 					<tbody>
// 					{filteredData.map((x,i) => <DynamicTableRow key={`${this.props.name}Row${i}`} titles={titleArr} data={x}/>)}
// 					</tbody>
// 				</table>
// 			</div>
// 		)
// 	}
// }

// class DynamicTableHeadView extends React.Component {
// 	render() {
// 		const titleArr = this.props.head;
// 		const headRender = <DynamicTableHead data={titleArr} name={this.props.name}/>;

// 		return (
// 			<div>
// 				<table className="table table-striped table-bordered">
// 					{headRender}
// 					<tbody>
// 					{this.props.data.map((x,i) => <DynamicTableRow key={`${this.props.name}Row${i}`} titles={titleArr} data={x}/>)}
// 					</tbody>
// 				</table>
// 			</div>
// 		)
// 	}
// }
