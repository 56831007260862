import React from 'react';
import TeamScheduleAlternate from '../components/schedule/TeamScheduleAlternate';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';
import { subscriptionsData } from '../services/subscriptions/mysubscriptionsActions.js';
import { schedData } from '../services/teamschedules/teamSchedulesActions.js';
import { Row, Col, Button, Container, Spinner } from 'react-bootstrap';
import MultiSelectCollapsible from '../components/schedule/MultiSelectCollabsable';
import PageFrame from '../containers/app/PageFrame.js';

class TeamSchedulesListView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: [],
			teamOptionsByCountry: [],
			country: null,
			selectedTeams: [],
			loadingTeams: false
		};
		this.changeHandler = this.changeHandler.bind(this);
		this.getTeamSchedules = this.getTeamSchedules.bind(this);
		this.teamSelectionChangeHandler =
			this.teamSelectionChangeHandler.bind(this);
	}

	componentDidMount() {
		this.props.getSubscriptionsData().then(subs => {
			// Preselect subscribed teams
			let subscribedTeams = subs.payload.subscriptions.map(x => x.id);

			let result = Object.entries(subs.payload.teamsByCountry).map(
				([country, teams]) => {
					let countryGroup = {
						label: country,
						expanded: subscribedTeams.some(subTeam =>
							teams.map(x => x.id).includes(subTeam)
						),
						options: teams.map(x => {
							return { label: x.team_name, value: x.id };
						})
					};
					return countryGroup;
				}
			);

			this.setState({
				teamOptionsByCountry: result,
				selectedTeams: subscribedTeams
			});

			// Load schedules for each subscribed team
			this.getTeamSchedules();
		});
	}

	getTeamSchedules() {
		let toRender = [...this.state.selectedTeams];
		this.setState({ loadingTeams: true });
		this.props
			.getTeamSchedulesData(toRender)
			.then(() => this.setState({ loadingTeams: false }));
	}

	changeHandler(key, event) {
		this.setState({
			[key]: event.target.value
		});
	}

	teamSelectionChangeHandler(selectedTeams) {
		if (selectedTeams.length > 0) {
			this.setState(
				{
					selectedTeams: selectedTeams.map(x => x.value)
				},
				this.getTeamSchedules
			);
		} else {
			this.setState({
				selectedTeams: []
			});
		}
	}

	render() {
		let teamSchedulesRender = null;
		if (this.state.teamOptionsByCountry.length > 0) {
			let sortedTeamSchedules = [];
			if (this.props.teams) {
				sortedTeamSchedules = Object.values(this.props.teams)
					.sort((a, b) => (a.title > b.title ? 1 : -1))
					.filter(x => x.games.length > 0);
			}

			teamSchedulesRender = (
				<Container className="TeamScheduleView">
					<Row>
						<Col>
							<MultiSelectCollapsible
								options={this.state.teamOptionsByCountry}
								selectedTeams={this.state.selectedTeams}
								placeholderButtonLabel={'Select teams'}
								onChange={this.teamSelectionChangeHandler}
							/>
						</Col>
						<Col>
							{this.props.teams && (
								<ReactToPrint
									trigger={() => (
										<Button variant="secondary">
											Print Schedules
										</Button>
									)}
									content={() => this.componentRef}
								/>
							)}
						</Col>
					</Row>
					{this.state.loadingTeams && (
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					)}
					{this.props.teams &&
						this.state.selectedTeams.length > 0 && (
							<div className="mt-2">
								<div ref={el => (this.componentRef = el)}>
									{sortedTeamSchedules.map(x => {
										return (
											<TeamScheduleAlternate
												key={x.title}
												team={x.title}
												events={[...x.games]}
												startTime={this.props.stime}
												endTime={this.props.etime}
												startDate={this.props.sday}
												endDate={this.props.eday}
											></TeamScheduleAlternate>
										);
									})}
								</div>
							</div>
						)}
				</Container>
			);
		}

		return (
			<PageFrame title="Schedules">
				<h1 className="dont-print">
					Please click the button on your screen to print. Do not use
					your PC's Shortcut (Control-P)!
				</h1>
				{teamSchedulesRender}
			</PageFrame>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.teamschedules;

	if (raw.data) {
		const teams = Object.entries(raw.data.teams).map(([k, v]) => {
			return {
				...v,
				id: k,
				games: v['games'].map(x => {
					return {
						...x,
						start: new Date(x.start),
						end: new Date(x.end)
					};
				})
			};
		});

		let stime = new Date();
		stime.setHours(...raw.data.stime.split(':').map(x => parseInt(x)));
		let etime = new Date();
		etime.setHours(...raw.data.etime.split(':').map(x => parseInt(x)));

		return {
			teams: teams,
			sday: new Date(raw.data.sday),
			eday: new Date(raw.data.eday),
			stime: stime,
			etime: etime
		};
	} else return { teams: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getTeamSchedulesData: teams => dispatch(schedData(teams)),
		getSubscriptionsData: () => dispatch(subscriptionsData())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TeamSchedulesListView);
