import React from 'react';
import { connect } from 'react-redux';
// import { Prompt } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

import {
	allSchedules,
	defaultSchedule,
	selectScheduleVersion,
	createGame,
	cancelGameCreation,
	createGameSetup,
	colorChartSave,
	summonplayers,
	unsetGameFilter,
	publishschedule,
	unpublishschedule,
	getaffectedteamsched,
	cancelschedchanges,
	confirmschedchanges
} from '../services/colorchart/colorchartActions';
import ColorChartDateSelector from '../components/schedule/ColorChartDateSelector.js';
import ColorChart from '../components/schedule/ColorChart.js';
import SearchableDropdown from '../components/general/SearchableDropdown.js';
import MultiMoveClipboard from '../components/schedule/MultiMoveClipboard.js';
import TrashBin from '../components/schedule/TrashBin.js';
import ColorChartGame from '../components/schedule/ColorChartGame.js';
import UndoRedo from '../components/schedule/UndoRedo.js';
import AddGameForm from '../components/schedule/AddGameForm.js';
import '../components/schedule/multigame.css';
import { request } from '../global/utils';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import MultiSelectSearchableDropdown from '../components/general/MultiSelectSearchableDropdown';
import { Modal, Row, Col, Card, Button, Container } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import AffectedTeamSchedules from '../components/schedule/AffectedTeamSchedules';
import './../containers/style/AdminScheduleView.css';
import Arrow from '../components/schedule/Arrow';
const d3 = require('d3-array');

// function getPtFromElem(base, elem) {
// 	return {
// 		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x + elem.getBoundingClientRect().width/2,
// 		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y + elem.getBoundingClientRect().height/2
// 	};
// }

function getLeftCenter(base, elem) {
	return {
		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x,
		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y + elem.getBoundingClientRect().height / 2
	};
}

function getRightCenter(base, elem) {
	return {
		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x + elem.getBoundingClientRect().width,
		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y + elem.getBoundingClientRect().height / 2
	};
}

function getBottomCenter(base, elem) {
	return {
		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x + elem.getBoundingClientRect().width / 2,
		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y + elem.getBoundingClientRect().height
	};
}

function getTopCenter(base, elem) {
	return {
		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x + elem.getBoundingClientRect().width / 2,
		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y
	};
}

function getTopLeft(base, elem) {
	return {
		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x,
		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y
	};
}

function getTopRight(base, elem) {
	return {
		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x + elem.getBoundingClientRect().width,
		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y
	};
}

function getBottomLeft(base, elem) {
	return {
		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x,
		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y + elem.getBoundingClientRect().height
	};
}

function getBottomRight(base, elem) {
	return {
		x: elem.getBoundingClientRect().x - base.getBoundingClientRect().x + elem.getBoundingClientRect().width,
		y: elem.getBoundingClientRect().y - base.getBoundingClientRect().y + elem.getBoundingClientRect().height
	};
}

function getCellFromGameID(gameID) {
	let gameCells = document.querySelectorAll('td p');
	for (let i = 0; i < gameCells.length; i++) {
		const cell = gameCells[i];
		if (cell.innerText.trim().split(' ').at(-1) == gameID) return cell;
	}
}

function getArrowPropsFromGameID(game1_id, game2_id) {
	let cell1 = getCellFromGameID(game1_id);
	let cell2 = getCellFromGameID(game2_id);

	let arrowcanvas = document.getElementById('arrowcanvas');
	if (cell1 && cell2) {
		// Set cell1 as the cell that is the left most
		if (cell1.getBoundingClientRect().x > cell2.getBoundingClientRect().x) {
			let temp = cell1;
			cell1 = cell2;
			cell2 = temp;
		}

		return {
			pt1: getRightCenter(arrowcanvas, cell1),
			pt2: getLeftCenter(arrowcanvas, cell2)
		};
	}
	return null;

	const cell1_x = cell1.getBoundingClientRect().x;
	const cell1_y = cell1.getBoundingClientRect().y;
	const cell2_x = cell2.getBoundingClientRect().x;
	const cell2_y = cell2.getBoundingClientRect().y;

	// If they have the same x value, it should be bottom center to top center
	if (cell1_x === cell2_x) {
		// Find top cell
		if (cell1_y > cell2_y) {
			// Cell 2 is the top cell
			return {
				pt1: getBottomCenter(arrowcanvas, cell2),
				pt2: getTopCenter(arrowcanvas, cell1)
			};
		} else {
			// Cell 1 is the top cell
			return {
				pt1: getTopCenter(arrowcanvas, cell2),
				pt2: getBottomCenter(arrowcanvas, cell1)
			};
		}
	}

	// If they have the same y value, it should be left center to right center
	else if (cell1_y == cell2_y) {
		// Cell 1 is the left cell
		return {
			pt1: getRightCenter(arrowcanvas, cell1),
			pt2: getLeftCenter(arrowcanvas, cell2)
		};
	}

	// If their values are different it should be the closest corner
	else {
		if (cell1_y < cell2_y) {
			// Cell 1 is the top cell
			return {
				pt1: getBottomCenter(arrowcanvas, cell1),
				pt2: getTopCenter(arrowcanvas, cell2)
			};
		} else {
			// Cell 2 is the top cell
			return {
				pt1: getTopCenter(arrowcanvas, cell1),
				pt2: getBottomCenter(arrowcanvas, cell2)
			};
		}
	}
}

function filteredOut(filter, game) {
	let games = filter.gameID.split(',');
	console.log(games);
	if (parseInt(filter.gameID) === game.game_id) return false;
	else if (filter.gameID !== '') return true;

	if (filter.tournamentID.includes(game.tournament_id)) return false;
	else if (filter.tournamentID.length !== 0) return true;

	if (filter.teamID !== null && filter.teamID === game.team1_id) return false;
	if (filter.teamID !== null && filter.teamID === game.team2_id) return false;

	// If all filters are null, show everything
	if (filter.gameID === '' && filter.tournamentID.length !== 0 && filter.teamID === null) {
		return false;
	}

	return true;
}

class AdminScheduleView extends React.Component {
	constructor() {
		super();

		this.state = {
			activeSchedule: null,
			selectedDate: '',
			message: '',
			creatingGame: false,
			newGame: {},
			newGameCount: 0,
			filter: {
				filtering: false,
				tournamentID: [],
				teamID: null,
				gameID: ''
			},
			summonMode: false,
			summonList: [],
			scores: '',
			scoresRenderStyle: {},
			b2bArrowPts: [],
			dblArrowPts: [],
			hideScoredGames: false
		};

		this.selectDate = this.selectDate.bind(this);
		this.selectScheduleVersion = this.selectScheduleVersion.bind(this);
		this.updateMessage = this.updateMessage.bind(this);
		this.updateDefaultSchedule = this.updateDefaultSchedule.bind(this);
		this.undoHandler = this.undoHandler.bind(this);
		this.redoHandler = this.redoHandler.bind(this);
		this.createGameHandler = this.createGameHandler.bind(this);
		this.createGame = this.createGame.bind(this);
		this.cancelGameCreation = this.cancelGameCreation.bind(this);
		this.saveChanges = this.saveChanges.bind(this);
		this.updateSearchGameID = this.updateSearchGameID.bind(this);
		this.clearFilter = this.clearFilter.bind(this);
		this.toggleTournament = this.toggleTournament.bind(this);
		this.toggleSummonMode = this.toggleSummonMode.bind(this);
		this.addToSummonList = this.addToSummonList.bind(this);
		this.removeFromSummonList = this.removeFromSummonList.bind(this);
		this.summonPlayers = this.summonPlayers.bind(this);
		this.showScores = this.showScores.bind(this);
		this.hideScores = this.hideScores.bind(this);
		this.enterArrowMode = this.enterArrowMode.bind(this);
		this.exitArrowMode = this.exitArrowMode.bind(this);
		this.toggleHideScoredGames = this.toggleHideScoredGames.bind(this);
	}

	async componentDidMount() {
		await this.props.getSchedules();
		console.log(this.props);
		if (this.props.initialFilterValue) {
			let gameID = parseInt(this.props.initialFilterValue[0]);

			let publishedSchedule = this.props.scheduleVersions.find(x => x.published === 1).schedule_key;
			let currentSchedule = this.props.schedulesByVersion[publishedSchedule];
			let clipboard = currentSchedule.clipboardGames.find(x => x.game_id === gameID);
			let selectedDate;
			if (!clipboard) {
				// Loop over each day
				for (const scheduleObj of currentSchedule.schedules) {
					let daySchedule = scheduleObj.schedule;
					console.log(daySchedule);
					for (const timeRow of daySchedule) {
						if (timeRow.hasOwnProperty('games')) {
							let isFound = timeRow.games.find(x => x.game_id === gameID);
							if (isFound) {
								selectedDate = scheduleObj.date;
							}
						}
					}
				}
			}

			console.log(selectedDate);
			this.setState({
				activeSchedule: this.props.defaultSchedule,
				selectedDate: selectedDate,
				filter: {
					filtering: true,
					tournamentID: [],
					teamID: null,
					gameID: this.props.initialFilterValue.join(',')
				}
			});
			this.props.unsetInitialFilter();
		} else {
			const today = new Date();
			const todayFormatted = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
			const dateOptions = this.props.schedulesByVersion[this.props.defaultSchedule].schedules.map(x => x.date);
			const todaySelected = dateOptions.includes(todayFormatted) ? todayFormatted : dateOptions[0];
			if (this.props.defaultSchedule) {
				this.setState({
					activeSchedule: this.props.defaultSchedule,
					selectedDate: todaySelected
				});
			} else {
				let selectedSchedule = this.props.scheduleVersions[0].schedule_key;
				this.setState({
					activeSchedule: selectedSchedule,
					selectedDate: todaySelected
				});
			}
		}
	}

	async enterArrowMode() {
		// Back to back arrows
		let b2b_result_raw = await request(
			`schedule/backtoback?date=${this.state.selectedDate}&schedule_version=${this.state.activeSchedule}`
		);
		let b2b_result = b2b_result_raw.data.content;
		let b2b_game_ids = [];
		for (const [time, b2b_games] of Object.entries(b2b_result.backtobacks_by_time)) {
			b2b_game_ids.push(
				...b2b_games.map(x => {
					return {
						id: x.id,
						past_id: x.past_id
					};
				})
			);
		}

		// Remove redundant game ids
		const unique_b2b_game_ids = Array.from(new Set(b2b_game_ids.map(JSON.stringify)), JSON.parse);

		let b2b_arrow_props = unique_b2b_game_ids.map(x => getArrowPropsFromGameID(x.id, x.past_id));
		b2b_arrow_props = b2b_arrow_props.filter(x => x);

		// Double booked arrows
		let result = await request('schedule/doublebooked');
		let dblBookedTeamInfo = result.data.content.doublebookedTeams;
		dblBookedTeamInfo = dblBookedTeamInfo.filter(x => x.date.split('T')[0] === this.state.selectedDate);
		let dblBookedArrowProps = dblBookedTeamInfo.map(x => {
			let id1 = x.game_ids.split(',')[0];
			let id2 = x.game_ids.split(',')[1];
			return getArrowPropsFromGameID(id1, id2);
		});
		dblBookedArrowProps = dblBookedArrowProps.filter(x => x);

		if (b2b_arrow_props.length === 0 && dblBookedArrowProps.length === 0) {
			window.alert('No double booked or back to back games on this day');
		} else {
			this.setState({
				b2bArrowPts: b2b_arrow_props,
				dblArrowPts: dblBookedArrowProps
			});
		}
	}

	exitArrowMode() {
		this.setState({
			b2bArrowPts: [],
			dblArrowPts: []
		});
	}

	undoHandler() {
		let actionToUndo = this.props.scheduleChanges[this.props.scheduleChanges.length - 1];
		if (actionToUndo.hasOwnProperty('game')) {
			this.setState({
				selectedDate: actionToUndo.game.date,
				creatingGame: false,
				newGame: {}
			});
		} else if (actionToUndo.hasOwnProperty('game1')) {
			this.setState({
				selectedDate: actionToUndo.game1.date,
				creatingGame: false,
				newGame: {}
			});
		}
		this.props.onUndo();
	}

	redoHandler() {
		if (this.props.canRedo) {
			let futureChanges = this.props.future[0].scheduleChanges;
			let actionToRedo = futureChanges[futureChanges.length - 1];
			if (actionToRedo.hasOwnProperty('game')) {
				this.setState({
					selectedDate: actionToRedo.game.date,
					creatingGame: false,
					newGame: {}
				});
			} else if (actionToRedo.hasOwnProperty('game1')) {
				this.setState({
					selectedDate: actionToRedo.game1.date,
					creatingGame: false,
					newGame: {}
				});
			}
			this.props.onRedo();
		}
	}

	createGameHandler(slot) {
		const gameObj = {
			schedule_key: this.state.activeSchedule,
			date: slot.date,
			time: slot.time,
			location: slot.location
		};
		this.props.createGameSetup(gameObj);
		this.setState({
			creatingGame: true,
			newGame: gameObj,
			newGameCount: this.state.newGameCount + 1
		});
	}

	createGame(gameInfo) {
		const tournamentsList = this.props.tournamentsBySchedule[this.state.newGame.schedule_key];
		const tournament = tournamentsList.find(x => x.tournamentId === gameInfo.tournament_id);
		const gameObj = {
			...this.state.newGame,
			game_id: `new${this.state.newGameCount}`,
			sport: tournament.sport,
			tournament_id: gameInfo.tournament_id,
			gameType: gameInfo.gameType,
			team1_id: gameInfo.team1_id,
			team2_id: gameInfo.team2_id,
			category: tournament.category,
			background: tournament.background,
			textcolor: tournament.text,
			bold: tournament.bold,
			text: `${gameInfo.text} new${this.state.newGameCount}`,
			strike: 0
		};

		this.props.addGame(gameObj);
		this.setState({
			creatingGame: false,
			newGame: {}
		});
	}

	cancelGameCreation(slot) {
		this.props.cancelGame(slot);
		this.setState({
			creatingGame: false,
			newGame: {}
		});
	}

	selectDate(event) {
		this.setState({ selectedDate: event });
	}

	updateMessage(message) {
		this.setState({ message: message });
		setTimeout(() => this.setState({ message: '' }), 2000);
	}

	updateDefaultSchedule() {
		this.props.updateDefaultSchedule(this.state.activeSchedule);
	}

	selectScheduleVersion(scheduleKey) {
		const schedule_key = parseInt(scheduleKey);
		this.props.changeScheduleVersion();
		this.props.clearHistory();
		this.setState({
			activeSchedule: schedule_key,
			selectedDate: this.props.schedulesByVersion[schedule_key].schedules[0].date
		});
	}

	async saveChanges(overwriteChanges) {
		let reason = window.prompt('Enter a reason for the schedule changes');

		let changes = [];
		for (const [gameID, changeIDs] of Object.entries(this.props.changesByGameID)) {
			const firstChange = this.props.scheduleChanges[changeIDs[0]];
			const lastChange = this.props.scheduleChanges[changeIDs[changeIDs.length - 1]];
			changes.push({
				gameID: gameID,
				firstChange: firstChange,
				lastChange: lastChange
			});
		}

		let teamsAffected = this.props.teamsAffected;
		// Push changes to temporary table in database
		await this.props.saveChanges({
			changes: changes,
			reason: reason,
			teamsAffected: teamsAffected,
			scheduleVersion: this.state.activeSchedule,
			overwriteChanges: overwriteChanges
		});

		// Get updated schedules for teams that would be affected
		this.props.getAffectedTeamSched({
			version: this.state.activeSchedule,
			teamsAffected: teamsAffected
		});
	}

	updateSearchGameID(event) {
		if (event.target.value === '') {
			if (this.state.filter.tournamentID.length === 0 && this.state.filter.teamID === null) {
				this.setState({
					filter: {
						filtering: false,
						gameID: '',
						tournamentID: [],
						teamID: null
					}
				});
				return;
			} else {
				this.setState({
					filter: {
						filtering: true,
						gameID: '',
						tournamentID: this.state.filter.tournamentID,
						teamID: this.state.filter.teamID
					}
				});
				return;
			}
		}

		let gameID = parseInt(event.target.value);
		let currentSchedule = this.props.schedulesByVersion[this.state.activeSchedule];
		let clipboard = currentSchedule.clipboardGames.find(x => x.game_id === gameID);

		if (clipboard) {
			this.setState({
				filter: {
					filtering: true,
					gameID: event.target.value,
					tournamentID: this.state.filter.tournamentID,
					teamID: this.state.filter.teamID
				}
			});
		} else {
			// Loop over each day
			for (const scheduleObj of currentSchedule.schedules) {
				let daySchedule = scheduleObj.schedule;
				for (const timeRow of daySchedule) {
					let firstRowGames = timeRow.firstRow.filter(x => x !== null);
					let firstRowMatch = firstRowGames.find(x => x.game_id === gameID);
					if (firstRowMatch) {
						this.setState({
							filter: {
								filtering: true,
								gameID: event.target.value,
								tournamentID: this.state.filter.tournamentID,
								teamID: this.state.filter.teamID
							},
							selectedDate: scheduleObj.date
						});
						return;
					} else {
						let secondRowGames = timeRow.secondRow.filter(x => x !== null);
						let secondRowMatch = secondRowGames.find(x => x.game_id === gameID);
						if (secondRowMatch) {
							this.setState({
								filter: {
									filtering: true,
									gameID: event.target.value,
									tournamentID: this.state.filter.tournamentID,
									teamID: this.state.filter.teamID
								},
								selectedDate: scheduleObj.date
							});
							return;
						}
					}
				}
			}
		}

		this.setState({
			filter: {
				filtering: false,
				gameID: event.target.value,
				tournamentID: [],
				teamID: null
			}
		});
	}

	clearFilter() {
		this.setState({
			filter: {
				filtering: false,
				gameID: '',
				tournamentID: [],
				teamID: null
			}
		});
	}

	toggleTournament(event) {
		const tournamentID = parseInt(event.target.value);
		if (this.state.filter.tournamentID.includes(tournamentID)) {
			if (
				this.state.filter.tournamentID.length === 1 &&
				this.state.filter.gameID === '' &&
				this.state.filter.teamID === null
			) {
				this.setState({
					filter: {
						filtering: false,
						gameID: '',
						tournamentID: [],
						teamID: null
					}
				});
			} else {
				this.setState({
					filter: {
						filtering: true,
						gameID: this.state.filter.gameID,
						tournamentID: this.state.filter.tournamentID.filter(x => x !== tournamentID),
						teamID: this.state.filter.teamID
					}
				});
			}
		} else {
			this.setState({
				filter: {
					filtering: true,
					gameID: this.state.filter.gameID,
					tournamentID: [...this.state.filter.tournamentID, tournamentID],
					teamID: this.state.filter.teamID
				}
			});
		}
	}

	toggleSummonMode() {
		if (this.state.summonMode) {
			this.setState({
				summonMode: !this.state.summonMode,
				summonList: []
			});
		} else {
			this.setState({
				summonMode: !this.state.summonMode
			});
		}
	}

	toggleHideScoredGames() {
		this.setState({
			hideScoredGames: !this.state.hideScoredGames
		});
	}

	addToSummonList(game) {
		let newSummonList = [...this.state.summonList];
		if (!this.state.summonList.includes(game.team1_id)) {
			newSummonList.push(game.team1_id);
		}
		if (!this.state.summonList.includes(game.team2_id)) {
			newSummonList.push(game.team2_id);
		}
		if (newSummonList.length > this.state.summonList.length) {
			this.setState({
				summonList: newSummonList
			});
		}
	}

	removeFromSummonList(id) {
		this.setState({
			summonList: this.state.summonList.filter(x => x !== id)
		});
	}

	summonPlayers() {
		this.props.summonPlayers(this.state.summonList);
		this.setState({
			summonMode: false,
			summonList: []
		});
	}

	showScores(game_id, event) {
		let bodyRect = document.getElementById('adminscheduleview').getBoundingClientRect();
		let { top, right } = event.target.getBoundingClientRect();
		const currentGame = this.props.scores.find(
			x => x.game_id === game_id && x.schedule_key === this.state.activeSchedule
		);
		let score;
		if (currentGame.set1 === null) {
			score = currentGame.score;
		} else if (currentGame.set3 === null) {
			score = `${currentGame.set1}; ${currentGame.set2}`;
		} else {
			score = `${currentGame.set1}; ${currentGame.set2}; ${currentGame.set3}`;
		}
		this.setState({
			scores: score,
			scoresRenderStyle: {
				position: 'absolute',
				top: `${top - bodyRect.top}px`,
				left: `${right - bodyRect.left}px`,
				zIndex: 10,
				color: 'white',
				background: 'black'
			}
		});
	}

	hideScores() {
		this.setState({
			scores: '',
			scoresRenderStyle: {}
		});
	}

	render() {
		if (this.props.schedulesByVersion && this.state.activeSchedule !== null) {
			let currentVersionInfo = this.props.schedulesByVersion[this.state.activeSchedule];
			let currentSchedule = currentVersionInfo.schedules;
			let dateOptions = currentVersionInfo.dates.map(x => ({
				id: x.date,
				text: x.formatdate
			}));

			dateOptions.sort((a, b) => {
				return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
			});

			let scheduleRender;
			if (this.state.selectedDate === '') scheduleRender = null;
			else {
				let daySchedule = currentSchedule.find(x => x.date === this.state.selectedDate);
				scheduleRender = (
					<ColorChart
						schedule={daySchedule.schedule}
						firstRowLocs={this.props.firstRowLocs}
						secondRowLocs={this.props.secondRowLocs}
						date={this.state.selectedDate}
						version={this.state.activeSchedule}
						sportsByLoc={this.props.sportsByLoc}
						updateMessage={this.updateMessage}
						createGameHandler={this.createGameHandler}
						selectDate={this.selectDate}
						filter={this.state.filter}
						showScores={this.showScores}
						hideScores={this.hideScores}
						summonMode={this.state.summonMode}
						hideScoredGames={this.state.hideScoredGames}
						addToSummonList={this.addToSummonList}
					/>
				);
			}

			let selectedSchedule = this.props.scheduleVersions.find(x => x.schedule_key === this.state.activeSchedule);

			const clippedGame = {
				borderBottom: '2px solid black',
				marginRight: '10px',
				padding: '0 5px 5px 0',
				minWidth: '10%'
				// minHeight: '50px'
			};

			const msgDiv = {
				color: '#9D0008',
				fontWeight: 'bold',
				width: '100vw',
				backgroundColor: '#FFC8CD',
				fontSize: '120%',
				textAlign: 'center',
				position: 'fixed',
				padding: '5px',
				top: '0',
				left: '0',
				margin: '0',
				zIndex: '10'
			};

			const messageRender = this.state.message === '' ? null : this.state.message;
			const messageStyle = this.state.message === '' ? { display: 'None' } : msgDiv;
			let defaultButtonRender;
			if (this.state.activeSchedule !== this.props.defaultSchedule) {
				defaultButtonRender = (
					<Button onClick={this.updateDefaultSchedule}>Save Current Schedule as Default</Button>
				);
			} else {
				// If default schedule is published
				if (this.props.isPublished) {
					defaultButtonRender = <Button onClick={this.props.unpublishSchedule}>Unpublish Schedule</Button>;
				} else {
					defaultButtonRender = <Button onClick={this.props.publishSchedule}>Publish Schedule</Button>;
				}
			}

			let createGameRender = null;
			if (this.state.creatingGame && this.state.newGame.location !== 'NA') {
				//Insert props here
				const newGameSchedVers = this.props.scheduleVersions.find(
					x => x.schedule_key === this.state.newGame.schedule_key
				);

				let availableTournamentIDs = this.props.teamsByScheduleAndTournament[this.state.activeSchedule];
				availableTournamentIDs = Object.keys(availableTournamentIDs).map(x => parseInt(x));

				//Get tournaments and teams based on schedule key
				let tournamentsList = this.props.tournamentsBySchedule[this.state.activeSchedule];
				let availableTournamentsList = tournamentsList.filter(x =>
					availableTournamentIDs.includes(x.tournamentId)
				);

				availableTournamentsList = availableTournamentsList.map(x => {
					return {
						id: x.tournamentId,
						text: x.name,
						sport: x.sport
					};
				});

				const sportsList = this.props.sportsByLoc[this.state.newGame.location].map(x => x.sport);
				availableTournamentsList = availableTournamentsList.filter(x => sportsList.includes(x.sport));

				const gameTypes = ['Tournament', 'Round - 1', 'Semi Final', 'Final'].map(x => {
					return { id: x, text: x };
				});

				const currentTeamsObj = this.props.teamsByScheduleAndTournament[this.state.activeSchedule];

				createGameRender = (
					<AddGameForm
						key={`newgame${this.state.newGameCount}`}
						schedule={newGameSchedVers.nickname}
						scheduleKey={this.state.activeSchedule}
						date={this.state.newGame.date}
						time={this.state.newGame.time}
						location={this.state.newGame.location}
						gameTypes={gameTypes}
						tournaments={availableTournamentsList}
						teamsByTournament={currentTeamsObj}
						createGame={this.createGame}
						cancelGameCreation={this.cancelGameCreation}
					/>
				);
			}

			let currentMultiMoveGames = this.props.schedulesByVersion[this.state.activeSchedule].clipboardGames;
			let filteredMultiMoveGames;
			if (this.state.filter.filtering) {
				filteredMultiMoveGames = currentMultiMoveGames.filter(x => !filteredOut(this.state.filter, x));
			} else {
				filteredMultiMoveGames = currentMultiMoveGames;
			}

			let multiMoveGamesSorted = [...filteredMultiMoveGames];
			multiMoveGamesSorted.sort((a, b) => {
				if (a.sport < b.sport) {
					return -1;
				} else if (a.sport === b.sport) {
					if (a.category < b.category) {
						return -1;
					} else return 1;
				} else return 1;
			});

			let searchRender = null;
			let allTournamentsList = this.props.tournamentsBySchedule[this.state.activeSchedule];
			allTournamentsList = allTournamentsList.map(x => {
				return {
					id: x.tournamentId,
					text: x.name,
					sport: x.sport
				};
			});

			if (!this.state.filter.filtering) {
				//Dont show cancel button
				searchRender = (
					<div>
						<p>Search and Filter</p>
						<input
							placeholder="Search Game ID"
							onChange={this.updateSearchGameID}
							value={this.state.filter.gameID}
						/>
						<MultiSelectSearchableDropdown
							selectorStyle="drop-down-select-scheduleview"
							itemsList={allTournamentsList}
							selectedItems={this.state.filter.tournamentID}
							searchable={true}
							toggleItem={this.toggleTournament}
						/>
					</div>
				);
			} else {
				//Show cancel button
				searchRender = (
					<div>
						<p>Search and Filter</p>
						<input
							placeholder="Search Game ID"
							onChange={this.updateSearchGameID}
							value={this.state.filter.gameID}
						/>
						<MultiSelectSearchableDropdown
							itemsList={allTournamentsList}
							selectedItems={this.state.filter.tournamentID}
							toggleItem={this.toggleTournament}
							searchable={true}
						/>
						<button onClick={this.clearFilter}>Clear</button>
					</div>
				);
			}

			let summonModeButtonRender;
			let teamsToSummonRender = null;
			if (this.state.summonMode) {
				summonModeButtonRender = <Button onClick={this.toggleSummonMode}>Leave Summon Mode</Button>;
				teamsToSummonRender = (
					<div>
						{this.state.summonList.map(x => (
							<p key={x} onClick={this.removeFromSummonList.bind(null, x)}>
								{this.props.teams.find(y => y.teamId === x).name}
							</p>
						))}
						<div>
							<Button onClick={this.summonPlayers}>Summon Players</Button>
						</div>
					</div>
				);
			} else {
				summonModeButtonRender = <Button onClick={this.toggleSummonMode}>Summon Mode</Button>;
			}

			let hideScoredGamesButtonRender;
			if (this.state.hideScoredGames) {
				hideScoredGamesButtonRender = <Button onClick={this.toggleHideScoredGames}>Show Scored Games</Button>;
			} else {
				hideScoredGamesButtonRender = <Button onClick={this.toggleHideScoredGames}>Hide Scored Games</Button>;
			}

			let scoresRender =
				this.state.scores === '' ? null : <p style={this.state.scoresRenderStyle}>{this.state.scores}</p>;

			let affectedTeamSchedRender = null;
			if (this.props.overwrite) {
				affectedTeamSchedRender = (
					<Modal show={true}>
						<Modal.Body>
							<p>There are pending schedule changes already. Would you like to overwrite them?</p>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={() => this.saveChanges(true)}>
								Overwrite
							</Button>
							<Button
								variant="secondary"
								onClick={this.props.cancelChanges.bind(null, this.state.activeSchedule)}
							>
								Cancel
							</Button>
						</Modal.Footer>
					</Modal>
				);
			} else if (this.props.affectedTeamSched) {
				console.log('Affected team sched');
				console.log(this.props.affectedTeamSched);
				affectedTeamSchedRender = (
					<AffectedTeamSchedules
						{...this.props.affectedTeamSched}
						cancelHandler={this.props.cancelChanges.bind(null, this.state.activeSchedule)}
						confirmHandler={this.props.confirmChanges.bind(null, {
							version: this.state.activeSchedule,
							team_ids: Object.keys(this.props.affectedTeamSched.affectedTeamSched)
						})}
					/>
				);
			}

			let arrowHeadWidth = 4;
			let arrowHeadHeight = 3;
			let b2b_color = 'blue';
			let dbl_color = 'red';

			return (
				<div>
					<Card id="adminscheduleview">
						{scoresRender}
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							Color Chart
						</Card.Header>
						<Card.Body>
							<Container fluid>
								<Row>
									<Row>
										<div style={messageStyle}>{messageRender}</div>
										<Col className="col-md-12 align-items-center border-bottom rounded border-2 mb-2">
											<Row>
												<Col>
													<SearchableDropdown
														itemsList={this.props.scheduleVersions.map(x => {
															return {
																id: x.schedule_key,
																text: x.nickname
															};
														})}
														searchable={false}
														selectedItem={selectedSchedule.schedule_key}
														selectItem={this.selectScheduleVersion}
													/>
												</Col>
												<Col>{defaultButtonRender}</Col>
												<Col>
													<UndoRedo
														canUndo={this.props.canUndo}
														canRedo={this.props.canRedo}
														onUndo={this.props.canUndo ? this.undoHandler : null}
														onRedo={this.props.canRedo ? this.redoHandler : null}
													/>
												</Col>
												<Col>
													<Button
														disabled={!this.props.canUndo}
														onClick={() => this.saveChanges(false)}
													>
														Save Changes
													</Button>
												</Col>
												<Col>{summonModeButtonRender}</Col>
												<Col>{hideScoredGamesButtonRender}</Col>
												<Col>
													{this.state.b2bArrowPts.length > 0 ||
													this.state.dblArrowPts.length > 0 ? (
														<Button onClick={this.exitArrowMode}>Exit Arrow Mode</Button>
													) : (
														<Button onClick={this.enterArrowMode}>Arrow Mode</Button>
													)}
												</Col>
												<Col>{searchRender}</Col>
												<Col>
													<ReactToPrint
														trigger={() => (
															<Button variant="secondary">Print Color Chart</Button>
														)}
														content={() => this.componentRef}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col
											md={10}
											ref={el => (this.componentRef = el)}
											style={{
												position: 'relative',
												padding: '0px'
											}}
										>
											<svg
												id="arrowcanvas"
												style={{
													position: 'absolute',
													height: '100%',
													width: '100%',
													pointerEvents: 'none'
												}}
											>
												<defs>
													<marker
														id={`startarrow${b2b_color}`}
														markerWidth={`${arrowHeadWidth}`}
														markerHeight={`${arrowHeadHeight}`}
														refX="2"
														refY={`${arrowHeadHeight / 2}`}
														orient="auto"
													>
														<polygon
															points={`${arrowHeadWidth} 0, ${arrowHeadWidth} ${arrowHeadHeight}, 0 ${
																arrowHeadHeight / 2
															}`}
															fill={b2b_color}
														/>
													</marker>
													<marker
														id={`endarrow${b2b_color}`}
														markerWidth={`${arrowHeadWidth}`}
														markerHeight={`${arrowHeadHeight}`}
														refX={`${arrowHeadHeight}`}
														refY={`${arrowHeadHeight / 2}`}
														orient="auto"
													>
														<polygon
															points={`0 0, ${arrowHeadWidth}, ${
																arrowHeadHeight / 2
															}, 0 ${arrowHeadHeight}`}
															fill={b2b_color}
														/>
													</marker>
													<marker
														id={`startarrow${dbl_color}`}
														markerWidth={`${arrowHeadWidth}`}
														markerHeight={`${arrowHeadHeight}`}
														refX="2"
														refY={`${arrowHeadHeight / 2}`}
														orient="auto"
													>
														<polygon
															points={`${arrowHeadWidth} 0, ${arrowHeadWidth} ${arrowHeadHeight}, 0 ${
																arrowHeadHeight / 2
															}`}
															fill={dbl_color}
														/>
													</marker>
													<marker
														id={`endarrow${dbl_color}`}
														markerWidth={`${arrowHeadWidth}`}
														markerHeight={`${arrowHeadHeight}`}
														refX={`${arrowHeadHeight}`}
														refY={`${arrowHeadHeight / 2}`}
														orient="auto"
													>
														<polygon
															points={`0 0, ${arrowHeadWidth}, ${
																arrowHeadHeight / 2
															}, 0 ${arrowHeadHeight}`}
															fill={dbl_color}
														/>
													</marker>
												</defs>
												{this.state.b2bArrowPts.map(x => (
													<Arrow {...x} color={b2b_color} />
												))}
												{this.state.dblArrowPts.map(x => (
													<Arrow {...x} color={dbl_color} />
												))}
											</svg>
											{scheduleRender}
										</Col>
										<Col md={2}>
											<ColorChartDateSelector
												itemsList={dateOptions}
												searchable={false}
												selectedItem={this.state.selectedDate}
												selectItem={this.selectDate}
											/>
											{createGameRender}
											{teamsToSummonRender}
											<TrashBin />
										</Col>
									</Row>
								</Row>
								<Row>
									<MultiMoveClipboard filter={this.state.filter}>
										{multiMoveGamesSorted.map(x => {
											return (
												<div style={clippedGame} key={`clip${x.game_id}`} className="multigame">
													<ColorChartGame
														{...x}
														filtering={this.state.filter.filtering}
														summonMode={this.state.summonMode}
														addToSummonList={this.addToSummonList}
														showSport={true}
													/>
													{/* <p className='sport'>{x.sport}</p> */}
												</div>
											);
										})}
									</MultiMoveClipboard>
								</Row>
							</Container>
						</Card.Body>
					</Card>
					{affectedTeamSchedRender}
					{/*TODO: Replace Prompt react router v6  */}
					{/* <Prompt
				when={this.props.scheduleChanges.length > 0}
				message="You have unsaved changes. Are you sure you want to leave?"
				/> */}
				</div>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let outputObj = {};
	let raw = state.colorchart.present;
	if (raw.initialGameFilter) {
		outputObj['initialFilterValue'] = raw.initialGameFilter;
	}

	if (raw.schedulesByVersion) {
		const pastExists = state.colorchart.past.length > 0;
		const initIsNotEmpty = JSON.stringify(state.colorchart.past[0]) !== JSON.stringify({});

		let groupedTeams = Object.fromEntries(
			d3.group(
				raw.teamOptions,
				x => x.schedule_key,
				x => x.tournament_id
			)
		);
		for (const [key, value] of Object.entries(groupedTeams)) {
			groupedTeams[key] = Object.fromEntries(value);
		}

		console.log(groupedTeams);

		// Get list of team ids that are currently affected by schedule changes
		let teamsAffected = [];
		raw.scheduleChanges.forEach(change => {
			switch (change.changeType) {
				case 'Clipboard':
					if (change.game.team1_id) teamsAffected.push(change.game.team1_id);
					else {
						console.log(groupedTeams);
						let teamsToAdd = groupedTeams[change.game.schedule_key];
						if (teamsToAdd.hasOwnProperty(change.game.tournament_id)) {
							teamsToAdd = teamsToAdd[change.game.tournament_id];
							teamsToAdd = teamsToAdd.map(x => x.teamId);
							teamsAffected.push(...teamsToAdd);
						}
					}

					if (change.game.team2_id) teamsAffected.push(change.game.team2_id);
					else {
						let teamsToAdd = groupedTeams[change.game.schedule_key];
						if (teamsToAdd.hasOwnProperty(change.game.tournament_id)) {
							teamsToAdd = teamsToAdd[change.game.tournament_id];
							teamsToAdd = teamsToAdd.map(x => x.teamId);
							teamsAffected.push(...teamsToAdd);
						}
					}

					break;
				case 'move':
					// Add teams to list if teamids are not null
					if (change.game.team1_id) teamsAffected.push(change.game.team1_id);
					else {
						let teamsToAdd = groupedTeams[change.game.schedule_key];
						if (teamsToAdd.hasOwnProperty(change.game.tournament_id)) {
							teamsToAdd = teamsToAdd[change.game.tournament_id];
							teamsToAdd = teamsToAdd.map(x => x.teamId);
							teamsAffected.push(...teamsToAdd);
						}
					}
					if (change.game.team2_id) teamsAffected.push(change.game.team2_id);
					break;
				case 'swap':
					// Add teams to list if teamids are not null
					if (change.game1.team1_id) teamsAffected.push(change.game1.team1_id);
					else {
						let teamsToAdd = groupedTeams[change.game1.schedule_key];
						if (teamsToAdd.hasOwnProperty(change.game1.tournament_id)) {
							teamsToAdd = teamsToAdd[change.game1.tournament_id];
							teamsToAdd = teamsToAdd.map(x => x.teamId);
							teamsAffected.push(...teamsToAdd);
						}
					}
					if (change.game1.team2_id) teamsAffected.push(change.game1.team2_id);
					if (change.game2.team1_id) teamsAffected.push(change.game2.team1_id);
					else {
						let teamsToAdd = groupedTeams[change.game2.schedule_key];
						if (teamsToAdd.hasOwnProperty(change.game2.tournament_id)) {
							teamsToAdd = teamsToAdd[change.game2.tournament_id];
							teamsToAdd = teamsToAdd.map(x => x.teamId);
							teamsAffected.push(...teamsToAdd);
						}
					}
					if (change.game2.team2_id) teamsAffected.push(change.game2.team2_id);
					break;
				case 'create':
					// Add teams to list if teamids are not null
					if (change.game.team1_id) teamsAffected.push(change.game.team1_id);
					else {
						let teamsToAdd = groupedTeams[change.game.schedule_key];
						if (teamsToAdd.hasOwnProperty(change.game.tournament_id)) {
							teamsToAdd = teamsToAdd[change.game.tournament_id];
							teamsToAdd = teamsToAdd.map(x => x.teamId);
							teamsAffected.push(...teamsToAdd);
						}
					}
					if (change.game.team2_id) teamsAffected.push(change.game.team2_id);
					break;
				case 'delete':
					// Add teams to list if teamids are not null
					if (change.game.team1_id) teamsAffected.push(change.game.team1_id);
					else {
						let teamsToAdd = groupedTeams[change.game.schedule_key];
						if (teamsToAdd.hasOwnProperty(change.game.tournament_id)) {
							teamsToAdd = teamsToAdd[change.game.tournament_id];
							teamsToAdd = teamsToAdd.map(x => x.teamId);
							teamsAffected.push(...teamsToAdd);
						}
					}
					if (change.game.team2_id) teamsAffected.push(change.game.team2_id);
					break;
			}
		});

		return Object.assign({}, outputObj, {
			schedulesByVersion: raw.schedulesByVersion,
			firstRowLocs: raw.firstRow,
			secondRowLocs: raw.secondRow,
			scheduleVersions: raw.scheduleVersions,
			defaultSchedule: raw.defaultSchedule,
			isPublished: raw.isPublished,
			sportsByLoc: raw.sportsByLoc,
			actionNumber: raw.actionNumber,
			scheduleChanges: raw.scheduleChanges,
			changesByGameID: raw.changesByGameID,
			canUndo: state.colorchart.past.length > 1 || (pastExists && initIsNotEmpty),
			canRedo: state.colorchart.future.length > 0,
			future: state.colorchart.future,
			tournamentsBySchedule: Object.fromEntries(d3.group(raw.tournamentOptions, x => x.schedule_key)),
			teamsByScheduleAndTournament: groupedTeams,
			teamsAffected: teamsAffected,
			teams: raw.teamOptions,
			gameNotes: raw.gameNotes,
			scores: raw.scores,
			affectedTeamSched: raw.affectedTeamSched,
			overwrite: raw.overwrite
		});
	} else
		return {
			schedulesByVersion: null,
			firstRowLocs: null,
			secondRowLocs: null,
			scheduleVersions: null,
			defaultSchedule: null,
			isPublished: null,
			sportsByLoc: null,
			actionNumber: null,
			scheduleChanges: null,
			changesByGameID: null,
			canUndo: false,
			canRedo: false,
			future: null,
			teamsAffected: null,
			teams: null,
			gameNotes: null,
			scores: null,
			affectedTeamSched: null,
			overwrite: false
		};
};

const mapDispatchToProps = dispatch => {
	return {
		getSchedules: () => dispatch(allSchedules()),
		updateDefaultSchedule: version => dispatch(defaultSchedule(version)),
		changeScheduleVersion: () => dispatch(selectScheduleVersion()),
		addGame: game => dispatch(createGame(game)),
		cancelGame: slot => dispatch(cancelGameCreation(slot)),
		createGameSetup: slot => dispatch(createGameSetup(slot)),
		onUndo: () => dispatch(UndoActionCreators.undo()),
		onRedo: () => dispatch(UndoActionCreators.redo()),
		saveChanges: obj => dispatch(colorChartSave(obj)),
		clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
		summonPlayers: teams => dispatch(summonplayers(teams)),
		unsetInitialFilter: () => dispatch(unsetGameFilter()),
		publishSchedule: () => dispatch(publishschedule()),
		unpublishSchedule: () => dispatch(unpublishschedule()),
		getAffectedTeamSched: version => dispatch(getaffectedteamsched(version)),
		cancelChanges: version => dispatch(cancelschedchanges(version)),
		confirmChanges: version => dispatch(confirmschedchanges(version))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminScheduleView);
