import React from 'react';
import { connect } from 'react-redux';
import { refschedulechangelog } from '../services/masterschedulechanges/masterschedulechangesAction';
import { Container } from 'react-bootstrap';
import RefScheduleInfoRow from '../components/schedule/RefScheduleInfoRow.js';
import PageFrame from '../containers/app/PageFrame.js';

class RefScheduleChangesView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			refSchedule: []
		};
	}
	componentDidMount() {
		this.props.getRefScheduleChanges().then(() => {
			this.setState({ refSchedule: this.props.refscheduleChanges });
		});
	}

	render() {
		let theaderStyle = {
			textAlign: 'left',
			paddingBottom: '15px',
			fontSize: '15px'
		};
		return (
			<PageFrame title="My Schedule Changes">
				<Container>
					<table className="table table-striped">
						<thead style={theaderStyle}>
							<tr>
								<th className="text-center" scope="col">
									Change Date
								</th>
								<th className="text-center" scope="col">
									Tournament
								</th>
								<th className="text-center" scope="col">
									Team 1 vs. Team 2
								</th>
								<th className="text-center" scope="col">
									Old Date
								</th>
								<th className="text-center" scope="col">
									Old Time
								</th>
								<th className="text-center" scope="col">
									Old Location
								</th>
								<th className="text-center" scope="col">
									New Date
								</th>
								<th className="text-center" scope="col">
									New Time
								</th>
								<th className="text-center" scope="col">
									New Location
								</th>
								<th className="text-center" scope="col">
									Sport
								</th>
								<th className="text-center" scope="col">
									Change Type
								</th>
							</tr>
						</thead>
						<tbody>
							{this.state.refSchedule.map(x => {
								return (
									<RefScheduleInfoRow
										key={x.change_id}
										{...x}
									/>
								);
							})}
						</tbody>
					</table>
				</Container>
			</PageFrame>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.masterschedulechanges;
	if (raw.refschedulechangelog) {
		return {
			refscheduleChanges: raw.refschedulechangelog
		};
	} else return { refscheduleChanges: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getRefScheduleChanges: () => dispatch(refschedulechangelog())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RefScheduleChangesView);
